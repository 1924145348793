/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
// import {IconUserModel} from '../../../modules/profile/ProfileModels'
import {DateTime} from 'luxon'
import {Dropdown1} from './Dropdown1'
import React, {useState, useEffect} from 'react'
import {string} from 'yup'
import {mls} from 'lib/multilanguagesupport'

type Props = {
  _id: string,
  icon: string
  badgeColor: string
  status: string
  repetitionType: string
  title: string
  description: string
  date: string
  usersReached: number
  progress: number
  successfulExecution: number
  durationStart: number
  durationEnd: number
  repeatOn: Array<string | number>,
  deliverAt: string,
  editCampaign: Function,
  deleteCampaign: Function,
}

const Cards: FC<Props> = ({
  _id,
  status,
  title,
  description,
  repetitionType,
  repeatOn,
  usersReached,
  successfulExecution,
  durationStart,
  durationEnd,
  deliverAt,
  editCampaign,
  deleteCampaign,
}) => {
  var [statusColor, updateStatusColor] = useState('primary')
  var [repitionColor, updateRepetitionColor] = useState('text-gray-600')
  var activeFontColor = 'primary'
  var inactiveFontColor = 'gray-600'
  var activeOpacity = '10'
  var inactiveOpacity = '50'
  var activeBgColor = 'primary'
  var inactiveBgColor = 'secondary'
  const days = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su']

  useEffect(() => {
    if (status === 'Active') {
      updateStatusColor('primary')
    } else {
      updateStatusColor('warning')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  var startDate = DateTime.fromMillis(durationStart || 0).toLocaleString()
  var endDate = DateTime.fromMillis(durationEnd || 0).toLocaleString()

  return (
    <div className='card border border-2 border-gray-300 border-hover'>
      <div className='card-header border-0 pt-3'>
        <div className='d-flex flex-row flex-fill align-items-center'>
          <div className='fw-bold text-gray-700 flex-grow-1' style={{fontSize: '14px'}}>
            {mls(title)}
          </div>

          <div className='d-flex flex-row  align-items-center'>
            <div className='ms-auto px-3'>
              <span className={`badge badge-light-${statusColor} fw-bolder px-3 py-2 mt-1 fs-9`}>
                {mls(status) || 'Inactive'}
              </span>
            </div>
            <div className='me-2'>
              <button
                className='btn btn-sm btn-icon btn-bg-secondary btn-active-color-primary'
                onClick={() => editCampaign(_id)}
              >
                <i className='bi bi-pencil-square fs-4'></i>
              </button>
              {/* <Dropdown1 /> */}
            </div>
            <div className='me-0'>
              <button
                className='btn btn-sm btn-icon btn-bg-secondary btn-active-color-primary'
                onClick={() => deleteCampaign(_id)}
              >
                <i className='bi bi-trash fs-4'></i>
              </button>
              {/* <Dropdown1 /> */}
            </div>
          </div>
        </div>
      </div>

      <div className='card-body py-0'>
        <p className='text-gray-400 fw-bold mt-1' style={{fontSize: '12px'}}>
          {mls(description)}
        </p>

        <div className='d-flex flex-wrap mb-1'>
          <div className=' '>
            <div className='fw-bold text-gray-400' style={{fontSize: '10px'}}>
              {mls('Deliver At')}
            </div>
            <div className=' text-gray-700 fw-bold' style={{fontSize: '10px'}}>
              {mls(deliverAt)}
            </div>
          </div>
          <div className='px-14'>
            <div className=' fw-bold text-gray-400' style={{fontSize: '10px'}}>
              {mls('Repeat')}
            </div>
            <div className=' text-gray-700 fw-bold' style={{fontSize: '10px'}}>
              {mls(repetitionType)}
            </div>
          </div>
          <div className=''>
            <div className='fw-bold text-gray-400' style={{fontSize: '10px'}}>
              {mls('Duration')}
            </div>
            <div className='text-gray-700 fw-bold' style={{fontSize: '10px'}}>
              <b>{startDate}</b> to <b>{endDate}</b>
            </div>
          </div>
        </div>
        <div
          className='h-1px w-100 bg-light mb-5'
          data-bs-toggle='tooltip'
          title='This project completed'
        ></div>

        <div className='d-flex flex-row pb-3'>
          {repetitionType === 'weekly'
            ? days.map((day) => {
                if ((repeatOn as Array<string>).includes(day)) {
                  return (
                    <div className='px-2'>
                      <span
                        className={`fw-bold px-3 py-2 text-${activeFontColor} rounded bg-${activeBgColor} bg-opacity-${activeOpacity} fs-8`}
                      >
                        {mls(day)}
                      </span>
                    </div>
                  )
                } else {
                  return (
                    <div className='px-2'>
                      <span
                        className={`fw-bold px-3 py-2 text-${inactiveFontColor} rounded bg-${inactiveBgColor} bg-opacity-${inactiveOpacity} fs-8`}
                      >
                        {mls(day)}
                      </span>
                    </div>
                  )
                }
              })
            : ((repeatOn as Array<string>) || []).map((date) => {
                return (
                  <div className='px-2'>
                    <span
                      className={`fw-bold px-3 py-2 text-${activeFontColor} rounded bg-${activeBgColor} bg-opacity-${activeOpacity}`}
                    >
                      {date}
                    </span>
                  </div>
                )
              })}
        </div>
        <div className='h-1px w-100 bg-light mb-5'></div>
        <div className='d-flex flex-wrap '>
          <div className=' mb-3'>
            <div className='fw-bold text-gray-400 ' style={{fontSize: '10px'}}>
              {mls('Users Reached')}
            </div>
            <div className=' text-gray-700 fw-bold text-end' style={{fontSize: '10px'}}>
              {usersReached}
            </div>
          </div>

          <div className='px-10 mb-3'>
            <div className='fw-bold text-gray-400' style={{fontSize: '10px'}}>
              {mls('Successful Execution')}
            </div>
            <div className=' text-gray-700 fw-bold text-end' style={{fontSize: '10px'}}>
              {successfulExecution}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cards
