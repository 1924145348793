import addBelongsToColumnSchema from "./addBelongsToColumnSchema";

function getValidatedColumnSchema(columnSchema, componentDefaultView, componentRelationsSchema) {
    
    if (!componentDefaultView) return columnSchema;
    const columns = {};
    (columnSchema || []).forEach((columnObj) => {        
        columns[columnObj.columnName] = columnObj;
        
    })
    const newColumnSchema = [];
    componentDefaultView.forEach((defaultColumn) => {
        const column = columns[defaultColumn.columnName];
        if (!column) return;
        
        if (column.dataType === 'belongsTo' && column.relationId) {
            const belongsToColumnsSchema = addBelongsToColumnSchema(column, componentRelationsSchema);
            belongsToColumnsSchema.forEach((belongsToColumn) => newColumnSchema.push({ ...belongsToColumn, belongsToColumnName: column.columnName }));
            return;
        }
        newColumnSchema.push({ ...column, name: defaultColumn.name });
    });
    return newColumnSchema;
}

export default getValidatedColumnSchema;
