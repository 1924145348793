import { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap-v5';
import { Link, useHistory } from 'react-router-dom';
const { DateTime } = require('luxon');

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

function normalizeDateString(dateStr) {
  // Regular expressions for various date formats
  const isoFormat = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;
  const dashFormat = /^\d{4}-\d{2}-\d{2}$/;
  const slashFormatMDY = /^\d{2}\/\d{2}\/\d{4}$/;
  const slashFormatYMD = /^\d{4}\/\d{2}\/\d{2}$/;
  const dotFormatDMY = /^\d{2}\.\d{2}\.\d{4}$/;
  const noDelimiterFormat = /^\d{8}$/;
  const dashFormatDMY = /^\d{2}-\d{2}-\d{4}$/;
  const slashFormatDMY = /^\d{2}\/\d{2}\/\d{4}$/;

  let normalizedDateStr = dateStr;

  if (isoFormat.test(dateStr)) {
    // Already in ISO format
    return dateStr;
  } else if (dashFormat.test(dateStr)) {
    // Date only format YYYY-MM-DD
    normalizedDateStr = `${dateStr}T00:00:00`;
  } else if (slashFormatMDY.test(dateStr)) {
    // Date format MM/DD/YYYY
    const [month, day, year] = dateStr.split('/');
    normalizedDateStr = `${year}-${month}-${day}T00:00:00`;
  } else if (slashFormatYMD.test(dateStr)) {
    // Date format YYYY/MM/DD
    const [year, month, day] = dateStr.split('/');
    normalizedDateStr = `${year}-${month}-${day}T00:00:00`;
  } else if (dotFormatDMY.test(dateStr)) {
    // Date format DD.MM.YYYY
    const [day, month, year] = dateStr.split('.');
    normalizedDateStr = `${year}-${month}-${day}T00:00:00`;
  } else if (noDelimiterFormat.test(dateStr)) {
    // Date format YYYYMMDD
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    normalizedDateStr = `${year}-${month}-${day}T00:00:00`;
  } else if (dashFormatDMY.test(dateStr)) {
    // Date format DD-MM-YYYY
    const [day, month, year] = dateStr.split('-');
    normalizedDateStr = `${year}-${month}-${day}T00:00:00`;
  } else if (slashFormatDMY.test(dateStr)) {
    // Date format DD/MM/YYYY
    const [day, month, year] = dateStr.split('/');
    normalizedDateStr = `${year}-${month}-${day}T00:00:00`;
  } else {
    // If format is not recognized, return the original string
    return 'Invalid date format';
  }

  return normalizedDateStr;
}
function formatValue(dataType, valueFormatted, value, data) {
  switch (dataType) {
    case 'date':
    case 'datePicker':
      if (isValidDate(value)) {
        return DateTime.fromJSDate(value).toLocaleString();
      }
      const dateTime = DateTime.fromISO(value).toLocaleString();
      if (!dateTime.isValid) {
        const normalizedValue = normalizeDateString(value);
        if (normalizedValue === 'Invalid date format') {
          return value; // returning Invalid Date format
        }
        return DateTime.fromISO(normalizedValue).toLocaleString();
      }
      return dateTime;
    case '__d3__CreatedAtDate':
      return DateTime.fromISO(data.__d3__createdAt).toLocaleString();
    case '__d3__CreatedAtTime':
      return DateTime.fromISO(data.__d3__createdAt).toLocaleString(DateTime.TIME_SIMPLE);
    case '__d3__UpdatedAtDate':
      return DateTime.fromISO(data.__d3__updatedAt).toLocaleString();
    case '__d3__UpdatedAtTime':
      return DateTime.fromISO(data.__d3__updatedAt).toLocaleString(DateTime.TIME_SIMPLE);
    default:
      break;
  }
  return valueFormatted ? valueFormatted : value;
}
const CellText = ({
  valueFormatted,
  value,
  setValue,
  colDef: {
    cellEditorParams: { columnData, componentID, parentScreen },
    ...colDef
  },
  data,
  ...props
}) => {
  const cellValue = formatValue(columnData?.dataType, valueFormatted, value, data);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const history = useHistory();
  // const buttonClicked = () => {
  //     alert(`${cellValue} medals won!`);
  //     setValue(cellValue + 1);
  // }
  let returnObj = (
    <>
      {cellValue?.length > 10 && (
        <Overlay target={target.current} show={show} placement='bottom'>
          {(props) => (
            <Tooltip id='overlay-example' {...props}>
              {cellValue}
            </Tooltip>
          )}
        </Overlay>
      )}

      <span ref={target} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        <span
          className='ag-cell-value'
          style={{ maxWidth: 550, width: '100%', userSelect: 'text' }}
        >
          {cellValue}
        </span>
        &nbsp;
      </span>
    </>
  );
  // adding clickable on id on to go to view/inner screen.
  if (columnData?.columnName === 'id' && parentScreen) {
    returnObj = (
      <Link
        to={`${history.location.pathname}/${componentID}?__d3__rowID=${cellValue}`}
      >
        {returnObj}
      </Link>
    );
  }
  // if (connectedScreenID) {
  //   returnObj = (
  //     <Link
  //       to={`${history.location.pathname}/${componentID}?${columnData.columnName}=${cellValue}`}
  //     >
  //       {returnObj}
  //     </Link>
  //   );
  // }
  return returnObj;
};

export default CellText;
