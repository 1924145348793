import React from 'react';
import {
  Dialog, DialogActions, DialogTitle, Button,
} from '@mui/material';
import PropTypes from 'prop-types';

const Popup = ({ showPopup, handleDelete, setShowPopup }) => {
  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <Dialog
      open={showPopup}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Are you sure to delete this ?
      </DialogTitle>
      <DialogActions>
        <Button autoFocus color="error" onClick={handleDelete}>
          Delete
        </Button>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;

Popup.propTypes = {
  showPopup: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  setShowPopup: PropTypes.func.isRequired,
};
