import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { validate } from 'lib/utils-library';
import 'bootstrap/dist/css/bootstrap.min.css';

const SearchSelect = ({
  d3Data,
  value = '',
  onChange = () => {},
  onBlur = () => {},
  err = '',
  items = [],
}) => {
  // const [value, setValue] = useState(value || d3Data.defaultValue || '');
  const [error, setError] = useState(err || null);
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef(null);

  // useEffect(() => {
  //   setValue(value || d3Data.defaultValue || '');
  // }, [d3Data.defaultValue]);

  const handleChange = (val) => {
    // setValue(val);
    onChange(val);
    const newError = validate(val, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };

  const handleBlur = () => {
    onBlur(value);
    const newError = validate(value, d3Data.validation);
    if (newError) {
      setError(newError.message);
    }
    setEditing(false);
  };

  const handleTextClick = () => {
    setEditing(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const renderInput = (props) => (
    <input
      {...props}
      className={`form-control ${err || error ? 'is-invalid' : ''}`}
      onBlur={handleBlur}
    />
  );

  const renderItem = (item, isHighlighted) => (
    <div
      key={item}
      className={`item ${isHighlighted ? 'bg-primary text-white' : ''}`}
      style={{ padding: '8px' }}
    >
      {item}
    </div>
  );

  return (
    <div className='row'>
      <label className='col-sm-4 col-lg-3 col-form-label'>{d3Data.name}</label>
      <div className='col-sm-8 col-lg-9' style={{ height: '45px' }}>
        {isEditing ? (
          <Autocomplete
            getItemValue={(item) => item}
            items={items}
            renderInput={renderInput}
            renderItem={renderItem}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            onSelect={(val) => handleChange(val)}
            inputProps={{ ref: inputRef }}
            wrapperStyle={{ position: 'relative' }}
            menuStyle={{
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              zIndex: 1000,
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: '4px',
              maxHeight: '200px',
              overflowY: 'auto',
            }}
          />
        ) : (
          <span
            className='form-control-plaintext'
            style={{
              textDecoration: 'underline dotted',
              cursor: 'pointer',
              color: value ? 'inherit' : '#009ef7',
            }}
            onClick={handleTextClick}
          >
            {value || 'Click to edit'}
          </span>
        )}
        {(err || error) && <div className='invalid-feedback'>{err || error}</div>}
      </div>
    </div>
  );
};

export default SearchSelect;

SearchSelect.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.string,
    validation: PropTypes.array,
  }).isRequired,
  value: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

SearchSelect.defaultProps = {
  value: '',
  onBlur: () => null,
  err: '',
};
