const en = {
  uploadCSV: 'Upload CSV',
  CSVFileUploadedSuccessfully: 'CSV file uploaded successfully',
  dragndropCSV: "Drag 'n' drop CSV file to upload content here, or click to select file",
  dropFilesHere: 'Drop the files here ...',
  clickToUpload: 'Click Upload to File',
  CheckBoxOptions: 'CheckBox Options',
  Yes: 'Yes',
  No: 'No',
  dataUploadedSuccessfully: 'Data Updated Successfully.',
  dataCreatedCuccessfully: 'Data Created Successfully.',
  addModule: 'Add New Module and Sub Module',
  mainModule: 'Make sure to enter unique Module Name',
  subModule: 'Make sure to enter unique Sub Module Name',
  navModule: 'Modules and Sub Modules in Navbar',
  selectModule: 'Select Module from below options',
  multiFormModule: 'All Modules and Sub Modules',
  dragModule: 'Drag this Module to re-arrange its position',
  dragSubModule: 'Drag this Sub Module to re-arrange its position',
  deleteModule: 'Removes Module with its corresponding Sub Modules',
  deleteSubModule: 'Removes this Sub Module',
  dataVisualize: 'Click and enter data below to get Data Visualizer',
  visualizeName: 'Enter the name of Visualizer',
  visualizeType: 'Select the type of Chart',
  visualizeOpType: 'Select the type of Output',
  visualizeUniqueField: 'Enter a field name',
  visualizeXAxis: 'Select parameter on X-Axis',
  visualizeCategories: 'Create Category for your Visualizer, you can have more than one too.',
  visualizeLabel: 'Enter the label of category',
  visualizeColor: 'Pick a suitable color for the category',
  visualizeValue: 'Enter value of the category',
  visualizeFilter: 'Filter for your Visualizer, its optional',
  visualizeDateRange: 'Enter the date type data',
  visualizeDateTimeGroup: 'Enter the default value of Time from options',
  Remove: 'Remove',
  close: 'Close',
  Module: 'Module',
  AddNewModule: 'Add New Module',
  SubModule: 'Sub-Module',
  AddFieldsinyourLayout: 'Add Fields in your Layout',
  AddField: 'Add Field',
  AddNewFields: 'Add New Fields',
  Save: 'Save',
  DataVisualization: 'Data Visualization',
  EditNewGraph: 'Edit New Graph',
  AddNewVisualizer: 'Add New Visualizer',
  CategoryAdded: 'Category Added',
  CannotAddCategory: 'Cannot add Category, please enter all values',
  CategoryRemoved: 'Category Removed',
  SelectAllFilters: 'Select All Filters',
  Filters: 'Filters',
  Categories: 'Categories',
  SelectAllCategories: 'Select All Categories',
  AddNewCategory: 'Add New Category',
  XAxisType: 'X-Axis Type',
  DateRange: 'DateRange',
  DateTimeGroup: 'Date Time Group',
  UniqueField: 'Unique Field',
  Label: 'Label',
  Color: 'Color',
  SumUpon: 'Sum Upon',
  AverageUpon: 'Average Upon',
  Operand1toMultiply: 'Operand 1 to Multiply',
  Operand2toMultiply: 'Operand 2 to Multiply',
  BarChart: 'Bar Chart',
  Donut: 'Donut',
  Pie: 'Pie',
  Line: 'Line',
  Area: 'Area',
  Count: 'Count',
  CountUnique: 'Count Unique',
  Sum: 'Sum',
  SumofProduct: 'Sum of Product',
  Category: 'Category',
  Average: 'Average',
  TimeSeries: 'Time Series',
  Number: 'Number',
  Date: 'Date',
  Day: 'Day',
  Year: 'Year',
  Month: 'Month',
  Quarter: 'Quarter',
  Week: 'Week',
  Hour: 'Hour',
  LastModified: 'Last Modified',
  CreatedAt: 'Created At',
  DisplayTextwhenValueisTrue: 'Display Text when Value is: True',
  DisplayTextwhenValueisFalse: 'Display Text when Value is: False',
  Key: 'Key',
  Text: 'Text',
  Password: 'Password',
  Select: 'Select',
  MultipleSelect: 'Multiple Select',
  AutoComplete: 'AutoComplete',
  Time: 'Time',
  DatewithTime: 'Date with Time',
  CheckBox: 'CheckBox',
  Switch: 'Switch',
  File: 'File',
  Image: 'Image',
  Type: 'Type',
  FieldName: 'Field Name',
  SetRoles: 'Set Roles',
  StakeHolders: 'StakeHolders',
  Create: 'Create',
  Read: 'Read',
  Update: 'Update',
  IsDetailedOnly: 'Is Detailed Only',
  IsDisabled: 'Is Disabled',
  ReadOnly: 'Read Only',
  AddNewOption: 'Add New Option',
  DynamicOptions: 'Dynamic Options',
  Options: 'Options',
  Value: 'Value',
  IsRequired: 'Is Required',
  IsLength: 'Is Length',
  IsEmail: 'Is Email',
  IsNumeric: 'Is Numeric',
  IsAlphabet: 'Is Alphabet',
  IsAlphabetorIsNumeric: 'Is Alphabet or Is Numeric',
  IsBool: 'Is Bool',
  IsCreditCard: 'Is Credit Card',
  IsCurrency: 'Is Currency',
  IsDate: 'Is Date',
  IsUppercase: 'Is Uppercase',
  IsLowercase: 'Is Lowercase',
  IsMobile: 'Is Mobile',
  IsUrl: 'Is Url',
  ErrorMessage: 'Error Message',
  MinimumLength: 'Minimum Length',
  MaximumLength: 'Maximum Length',
  Error: 'Error',
  MouduleSubModuleCannotBeSameAsParent: 'Moudule SubModule Cannot be same as Parent!',
  ThisFieldisRequired: 'This field is required.',
  ModuleStructure: 'Module Structure',
  SubModuleaddedSuccessfully: 'Sub Module added Successfully',
  ThisModuleandSubModulealreadyexist: 'This Module and Sub Module already exist!',
  ModuleandSubModuleaddedSuccessfully: 'Module and Sub Module added Successfully',
  BothModuleandSubModulevaluesarerequired: 'Both Module and Sub Module values are required',
  DefaultLanguage: 'Default Language:',
  AutoTranslateRemainingTexts: 'Auto Translate Remaining Texts',
  AddLanguage: 'Add Language',
  SubModules: 'Sub-Modules',
  AddNewSubModule: 'Add New SubModule',
  ModuleSettings: 'Module Settings',
  EditNewModule: 'Edit New Module',
  SubModuleName: 'Sub Module Name',
  IconLink: 'Icon Link',
  ShowinNavbar: 'Show in Navbar',
  Roles: 'Roles',
  AccessRoles: 'Access Roles',
  Stakeholders: 'Stakeholders',
  Access: 'Access',
  AppSettings: 'App Settings',
  FormLayout: 'Form Layout',
  WorkFlow: 'Work Flow',
  Settings: 'Settings',
  DataVisualisation: 'Data Visualisation',
  NewDesignation: 'New Designation',
  EditDesignationPressEnter: 'Edit designation & press enter',
  Designation: 'Designation',
  UserDesignations: 'User Designations',
  ReloadToolTip: 'Reload',
  Filter: 'Filter',
  ResetFilter: 'Reset Filter',
  Expand: 'Expand',
  Daily: 'Daily',
  Weekly: 'Weekly',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  Yearly: 'Yearly',
  CreateWorkflow: 'Create Workflow',
  SelectActionType: 'Select Action Type',
  GeneratePDF: 'Generate PDF',
  Mail: 'Mail',
  SMS: 'SMS',
  AddNewAction: 'Add New Action',
  PDFName: 'PDF Name',
  DownloadFileName: 'Download File Name',
  FormName: 'Form Name',
  SaveallForms: 'Save all Forms',
  Name: 'Name',
  GoToStatus: 'Go To Status',
  ButtonStyle: 'Button Style',
  Contained: 'Contained',
  Outlined: 'Outlined',
  HideAdvanced: 'Hide Advanced',
  ShowAdvanced: 'Show Advanced',
  SetButtonAccess: 'Set Button Access',
  AccessTo: 'Access To',
  ButtonSettings: 'Button Settings',
  NewButton: 'New Button',
  AddNewButton: 'Add New Button',
  RoleAccess: 'Role Access',
  Delete: 'Delete',
  StateSettings: 'State Settings',
  StateName: 'State Name',
  AddNew: 'Add New',
  ColumnName: 'Column Name',
  ColumnValue: 'Column Value',
  EntertheStateName: 'Enter the State Name',
  EditSettings: 'Edit Settings',
  EditWorkflowSettings: 'Edit Workflow Settings',
  ParentForm: 'Parent Form',
  ReferenceVariable: 'Reference Variable',
  ReferenceName: 'Reference Name',
  HeaderColumn: 'Header Column',
  HeaderValues: 'Header Values',
  Style: 'Style',
  VerticalTabs: 'Vertical Tabs',
  SinglePage: 'Single Page',
  SelectFormfromanyModule: 'Select Form from any Module',
  LayoutType: 'Layout Type',
  SamePageCRUD: 'Same Page CRUD',
  FormOnly: 'Form Only',
  MultiColumnForm: 'Multi Column Form',
  ModuleHere: 'Module: ',
  SubModuleHere: 'Sub Module: ',
  ViewAccesstousers: 'View Access to users:',
  Email: 'Email',
  ConfirmPassword: 'Confirm Password',
  UserID: 'User ID',
  Role: 'Role',
  IsActive: 'Is Active',
  ModuleLayoutType: 'Module Layout Type',
  None: 'None',
  MultiForm: 'Multi Form',
  OtherSettings: 'Other Settings',
  ModuleLayout: 'Module Layout',
  Database: 'Database',
  LoginForm: 'Login Form',
  ReadSettings: 'Read Settings',
  Show: 'Show',
  InputType: 'Input Type',
  AddNewCondition: 'Add New Condition',
  DownloadCSV: 'Download CSV',
  NewForm: 'New Form',
  AddNewForm: 'Add New Form',
  Constant: 'Constant',
  UserInput: 'User Input',
  Session: 'Session',
  Thisfieldisrequired: 'This field is required',
  NoBack: 'No Back',
  GeneralSettings: 'General Settings',
  MultiLanguageSupport: 'Multi Language Support',
  Thisfieldisarequiredfield: 'This field is a required field',
  Dateisnotafterspecifieddate: 'Date is not after specified date',
  Containscharactersotherthanalphabet: 'Contains characters other than alphabet',
  Containscharactersotherthanalphabetandnumbers: 'Contains characters other than alphabet and numbers',
  Dateisnotbeforespecifieddate: 'Date is not before specified date',
  Notaboolean: 'Not a boolean',
  notavalidcard: 'Not a valid Credit Card',
  notavalidcurrencyamount: 'not a valid currency amount',
  Itsnotavaliddateornotinspecifiedformat: "It's not a valid date or not in specified format",
  Itsnotdivisibleby: "It's not divisible by: ",
  PleaseenteravalidEmail: 'Please enter a valid Email!',
  IncorrectLengthlengthshouldbebetween: 'Incorrect Length... length should be between',
  Notinlowercase: 'Not in lowercase',
  Pleaseprovideacorrectphonenumber: 'Please provide a correct phone number',
  NotaMongoDBID: 'Not a MongoDB ObjectID',
  PleaseenteraNumber: 'Please enter a Number',
  Notinuppercase: 'Not in uppercase',
  specifiedliastisnotanarray: 'Specified liast is not an array',
  valueisnotexcludedfromthelist: 'Value is not excluded from the list',
  specifiedlistisnotanarray: 'Specified list is not an array',
  valueisnotincludedinthelist: 'Value is not included in the list',
  NotaUUID: 'Not a UUID',
  NotaURL: 'Not a URL',
  ChangePassword: 'Change Password',
  Close: 'Close',
  Passwordshouldbeofminimum6digits: 'Password should be of minimum 6 digits',
  Passwordsdidnotmatch: 'Passwords did not match!',
  PasswordChangedSuccessfully: 'Password Changed Successfully!',
  CurrentPassword: 'Current Password *',
  NewPassword: 'New Password *',
  ConfirmNewPassword: 'Confirm New Password *',
  Users: 'Users',
  ConfigLayout: 'Config Layout',
  English: 'English',
  Japanese: '日本語',
  Hindi: 'हिंदी',
  Italian: 'Italiano',
  French: 'français',
  German: 'Deutsche',
  Russian: 'русский',
  Korean: '한국어',
  Latin: 'Latine',
  Turkish: 'Türk',
  Spanish: 'Español',
  ModuleName: 'Module Name',
  Cancel: 'Cancel',
  EditNewField: 'Edit New Field',
  Logout: 'Logout',
  AddNewValidation: 'Add New Validation',
  Validation: 'Validation',
  OpType: 'Op Type',
  CreatedAtDate: 'Created At Date',
  CreatedAtTime: 'Created At Time',
  UpdatedAtDate: 'Updated At Date',
  UpdatedAtTime: 'Updated At Time',
  ExportCSV: 'Export CSV',
  Home: 'Home',
  From: 'From',
  To: 'To',
  ScaleDown: 'ScaleDown',
  Loading: 'Loading...',
  Rowsperpage: 'Rows per page:',
  torchOff: 'Turn Torch Off',
  torchOn: 'Turn Torch On',
  scanAppIDQRCode: 'Please Scan Your App ID QR Code',
  OK: 'OK',
  invalidQRCode: 'Invalid QR Code, Please contact your admin!',
  welcome: 'Welcome',
  email: 'Email',
  PleaseenteravalidPassword: 'Please enter a strong Password of at least 6 characters long!',
  'No App Connected to this account!': 'No App Connected to this account!',
  ForgotPassword: 'Forgot Password?',
  TermsAndConditions: 'Terms and Conditions.',
  termsAgreement: 'By pressing Login Button you agree to our ',
  addNewProject: 'Add New Project',
  editProjectSettings: 'Edit Project Settings',
  YourProjects: 'Your Projects',
  AddNewProject: 'Add New Project',
  NameOfProject: 'Name of Project',
  SelectProjectColor: 'Select Project Color',
  ContinueMakingSheet: 'Continue Making Sheet',
  skip: 'Skip',
  AddSheettothisProject: 'Add Sheet to this Project',
  more: 'more',
  PleaseEnterNameofthisProject: 'Please Enter Name of this Project',
  NameOfSheet: 'Name Of Sheet',
  cancel: 'Cancel',
  CreateNewPassword: 'Create New Password',
  Submit: 'Submit',
  OTP: 'OTP',
  EnterValidOTP: 'Please Enter Valid OTP',
  SignIn: 'Sign In',
  SignUp: 'Sign up',
  SignInToApp: 'Sign in to our app',
  SendOTP: 'Send OTP',
  EnterCompanyEmail: 'Enter your Company Email ID',
  NewCompany: 'New Company? Signup',
  ForgotAppURL: 'Forgot App URL?',
  SelectCountry: 'Select Country',
  EnterURLExtension: 'Enter URL Extension',
  EmailID: 'Email ID',
  NameOfCompany: 'Name of your Company',
  UnlockPowerOfAutomation: 'A step away to unlock the power of Automation',
  PeterDruckerQuote: '“The best way to predict the future is to create it.” - Peter Drucker',
  VinceLombardiQuote: '“Winners never quit and quitters never win.” - Vince Lombardi',
  ThomasEdisonQuote: '“Genius is 1% inspiration, and 99% perspiration.” - Thomas Edison',
  NapoleanHillQuote: '“If you cannot do great things, do small things in a great way.” - Napoleon Hill',
  India: 'India',
  Japan: 'Japan',
  'United States': 'United States',
  Canada: 'Canada',
  Afghanistan: 'Afghanistan',
  Albania: 'Albania',
  Algeria: 'Algeria',
  Andorra: 'Andorra',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  'Antigua & Barbuda': 'Antigua & Barbuda',
  Argentina: 'Argentina',
  Armenia: 'Armenia',
  Aruba: 'Aruba',
  Australia: 'Australia',
  Austria: 'Austria',
  Azerbaijan: 'Azerbaijan',
  Bahamas: 'Bahamas',
  Bahrain: 'Bahrain',
  Bangladesh: 'Bangladesh',
  Barbados: 'Barbados',
  Belarus: 'Belarus',
  Belgium: 'Belgium',
  Belize: 'Belize',
  Benin: 'Benin',
  Bermuda: 'Bermuda',
  Bhutan: 'Bhutan',
  Bolivia: 'Bolivia',
  'Bosnia & Herzegovina': 'Bosnia & Herzegovina',
  Botswana: 'Botswana',
  Brazil: 'Brazil',
  'British Virgin Islands': 'British Virgin Islands',
  Brunei: 'Brunei',
  Bulgaria: 'Bulgaria',
  'Burkina Faso': 'Burkina Faso',
  Burundi: 'Burundi',
  Cambodia: 'Cambodia',
  Cameroon: 'Cameroon',
  'Cape Verde': 'Cape Verde',
  'Cayman Islands': 'Cayman Islands',
  Chad: 'Chad',
  Chile: 'Chile',
  China: 'China',
  Colombia: 'Colombia',
  Congo: 'Congo',
  'Cook Islands': 'Cook Islands',
  'Costa Rica': 'Costa Rica',
  'Cote D Ivoire': 'Cote D Ivoire',
  Croatia: 'Croatia',
  'Cruise Ship': 'Cruise Ship',
  Cuba: 'Cuba',
  Cyprus: 'Cyprus',
  'Czech Republic': 'Czech Republic',
  Denmark: 'Denmark',
  Djibouti: 'Djibouti',
  Dominica: 'Dominica',
  'Dominican Republic': 'Dominican Republic',
  Ecuador: 'Ecuador',
  Egypt: 'Egypt',
  'El Salvador': 'El Salvador',
  'Equatorial Guinea': 'Equatorial Guinea',
  Estonia: 'Estonia',
  Ethiopia: 'Ethiopia',
  'Falkland Islands': 'Falkland Islands',
  'Faroe Islands': 'Faroe Islands',
  Fiji: 'Fiji',
  Finland: 'Finland',
  France: 'France',
  'French Polynesia': 'French Polynesia',
  'French West Indies': 'French West Indies',
  Gabon: 'Gabon',
  Gambia: 'Gambia',
  Georgia: 'Georgia',
  Germany: 'Germany',
  Ghana: 'Ghana',
  Gibraltar: 'Gibraltar',
  Greece: 'Greece',
  Greenland: 'Greenland',
  Grenada: 'Grenada',
  Guam: 'Guam',
  Guatemala: 'Guatemala',
  Guernsey: 'Guernsey',
  Guinea: 'Guinea',
  'Guinea Bissau': 'Guinea Bissau',
  Guyana: 'Guyana',
  Haiti: 'Haiti',
  Honduras: 'Honduras',
  'Hong Kong': 'Hong Kong',
  Hungary: 'Hungary',
  Iceland: 'Iceland',
  Indonesia: 'Indonesia',
  Iran: 'Iran',
  Iraq: 'Iraq',
  Ireland: 'Ireland',
  'Isle of Man': 'Isle of Man',
  Israel: 'Israel',
  Italy: 'Italy',
  Jamaica: 'Jamaica',
  Jersey: 'Jersey',
  Jordan: 'Jordan',
  Kazakhstan: 'Kazakhstan',
  Kenya: 'Kenya',
  Kuwait: 'Kuwait',
  'Kyrgyz Republic': 'Kyrgyz Republic',
  Laos: 'Laos',
  Latvia: 'Latvia',
  Lebanon: 'Lebanon',
  Lesotho: 'Lesotho',
  Liberia: 'Liberia',
  Libya: 'Libya',
  Liechtenstein: 'Liechtenstein',
  Lithuania: 'Lithuania',
  Luxembourg: 'Luxembourg',
  Macau: 'Macau',
  Macedonia: 'Macedonia',
  Madagascar: 'Madagascar',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Maldives: 'Maldives',
  Mali: 'Mali',
  Malta: 'Malta',
  Mauritania: 'Mauritania',
  Mauritius: 'Mauritius',
  Mexico: 'Mexico',
  Moldova: 'Moldova',
  Monaco: 'Monaco',
  Mongolia: 'Mongolia',
  Montenegro: 'Montenegro',
  Montserrat: 'Montserrat',
  Morocco: 'Morocco',
  Mozambique: 'Mozambique',
  Namibia: 'Namibia',
  Nepal: 'Nepal',
  Netherlands: 'Netherlands',
  'Netherlands Antilles': 'Netherlands Antilles',
  'New Caledonia': 'New Caledonia',
  'New Zealand': 'New Zealand',
  Nicaragua: 'Nicaragua',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  Norway: 'Norway',
  Oman: 'Oman',
  Pakistan: 'Pakistan',
  Palestine: 'Palestine',
  Panama: 'Panama',
  'Papua New Guinea': 'Papua New Guinea',
  Paraguay: 'Paraguay',
  Peru: 'Peru',
  Philippines: 'Philippines',
  Poland: 'Poland',
  Portugal: 'Portugal',
  'Puerto Rico': 'Puerto Rico',
  Qatar: 'Qatar',
  Reunion: 'Reunion',
  Romania: 'Romania',
  Russia: 'Russia',
  Rwanda: 'Rwanda',
  'Saint Pierre & Miquelon': 'Saint Pierre & Miquelon',
  Samoa: 'Samoa',
  'San Marino': 'San Marino',
  Satellite: 'Satellite',
  'Saudi Arabia': 'Saudi Arabia',
  Senegal: 'Senegal',
  Serbia: 'Serbia',
  Seychelles: 'Seychelles',
  'Sierra Leone': 'Sierra Leone',
  Singapore: 'Singapore',
  Slovakia: 'Slovakia',
  Slovenia: 'Slovenia',
  'South Africa': 'South Africa',
  'South Korea': 'South Korea',
  Spain: 'Spain',
  'Sri Lanka': 'Sri Lanka',
  'St. Kitts & Nevis': 'St. Kitts & Nevis',
  'St. Lucia': 'St. Lucia',
  'St. Vincent': 'St. Vincent',
  Sudan: 'Sudan',
  Suriname: 'Suriname',
  Swaziland: 'Swaziland',
  Sweden: 'Sweden',
  Switzerland: 'Switzerland',
  Syria: 'Syria',
  Taiwan: 'Taiwan',
  Tajikistan: 'Tajikistan',
  Tanzania: 'Tanzania',
  Thailand: 'Thailand',
  "Timor L'Este": "Timor L'Este",
  Togo: 'Togo',
  Tonga: 'Tonga',
  'Trinidad & Tobago': 'Trinidad & Tobago',
  Tunisia: 'Tunisia',
  Turkey: 'Turkey',
  Turkmenistan: 'Turkmenistan',
  'Turks & Caicos': 'Turks & Caicos',
  Uganda: 'Uganda',
  Ukraine: 'Ukraine',
  'United Arab Emirates': 'United Arab Emirates',
  'United Kingdom': 'United Kingdom',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Uzbekistan',
  Venezuela: 'Venezuela',
  Vietnam: 'Vietnam',
  'Virgin Islands (US)': 'Virgin Islands (US)',
  Yemen: 'Yemen',
  Zambia: 'Zambia',
  Zimbabwe: 'Zimbabwe',

  Registration: 'Registration',
  Contact: 'Contact',
  Donation: 'Donation',
  ClickOnInputToEditField: 'Click on Input to edit field',
  FieldSettings: 'Field Settings',
  ClickOnAFieldToEdit: 'Click on a Field to edit',
  SFURTIRegistrationForm: 'SFURTI Registration Form',
  ClickHereToCreate: 'Click here to Create',
  OR: 'OR',
  CreateNewInputField: 'Create New Input Field',
};

export default en;
