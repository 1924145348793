import React, {useState} from 'react'
import Checkbox from '@mui/material/Checkbox' // import InputLabel from '@mui/material/InputLabel'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {makeStyles} from '@mui/styles'
import {createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles'
import {mls} from 'lib/multilanguagesupport'
// import {MenuProps, useStyles, options} from './utils'
const theme = createTheme()
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
}

function MultiSelectDropdown({fieldSchema, setNewValue, fetchedItems, value = [] }) {
  const classes = useStyles()
  const [selected, setSelected] = useState([...value]);
  console.log(fetchedItems);
  // const isAllSelected = fetchedItems
  //   ? fetchedItems.length > 0 && selected.length === fetchedItems.length
  //   : fieldSchema.selectMenu.length > 0 && selected.length === fieldSchema.selectMenu.length

  const handleChange = (event) => {
    const value = event.target.value
    // if (value[value.length - 1] === 'all') {
    //   setSelected(
    //     fetchedItems
    //       ? selected.length === fetchedItems.length
    //         ? []
    //         : fetchedItems
    //       : selected.length === fieldSchema.selectMenu.length
    //       ? []
    //       : fieldSchema.selectMenu
    //   )
    //   return
    // }
    setSelected(value)

    setNewValue({[fieldSchema.columnName]: value})
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <FormControl className={classes.formControl}>
          <Select
            labelId='mutiple-select-label'
            variant="filled"
            multiple
            value={selected}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            autoWidth
          >
            {/* <MenuItem
              value='all'
              classes={{
                root: isAllSelected ? classes.selectedAll : '',
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{indeterminate: classes.indeterminateColor}}
                  checked={isAllSelected}
                  indeterminate={
                    fetchedItems
                      ? selected.length > 0 && selected.length < fetchedItems.length
                      : selected.length > 0 && selected.length < fieldSchema.selectMenu.length
                  }
                />
              </ListItemIcon>
              <ListItemText classes={{primary: classes.selectAllText}} primary='Select All' />
            </MenuItem> */}
            {fetchedItems
              ? (fetchedItems || []).map((menu: distinctVal) => (
                  <MenuItem key={menu.distinctVal} value={mls(menu.distinctVal)}>
                    <ListItemIcon>
                      <Checkbox checked={selected.indexOf(menu.distinctVal) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={mls(menu.distinctVal)} />
                  </MenuItem>
                ))
              : (fieldSchema.selectMenu || []).map((menu) => (
                  <MenuItem key={menu.value} value={menu.value}>
                    <ListItemIcon>
                      <Checkbox checked={selected.indexOf(menu.value) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={mls(menu.label)} />
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default MultiSelectDropdown
