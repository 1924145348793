import { FC, useState } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { Button, Modal } from "react-bootstrap-v5";
import { mls } from "lib/multilanguagesupport";
import { useDispatch } from "react-redux";
import { create } from "redux/stateSlice";
import reduxConstants from 'redux/reduxConstants';

const Papa = require('papaparse');

const numericDataTypes = ['number', 'currency'];
const booleanDataTypes = ['switch', 'checkbox', 'boolean'];

const CSVUpload = ({ uploadCSV, closeModal, tableSchema }) => {
  const [columnTypeObj] = useState(() => {
    const columnObj = {};
    (tableSchema?.columnSchema || []).forEach((column) => columnObj[column?.columnName] = column?.dataType);
    return columnObj;
  });
  console.log(columnTypeObj, tableSchema, 'columnTypeObj');
  const [jsonData, setJSONData] = useState([]);
  const dispatch = useDispatch();
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop: (acceptedFiles) => {
      var reader = new FileReader();
      reader.onload = function(e) {
          // Use reader.result
          console.log(reader.result, 'result');
          const json = Papa.parse(reader.result, {
            header: true,
            transformHeader: (header) => {
              if (header === 'id') {
                return '__d3__id';
              }
              return header;
            },
            transform: (value, field) => {
              if (numericDataTypes.includes(columnTypeObj[field])) {
                return Number(value) || null;
              }
              if (booleanDataTypes.includes(columnTypeObj[field])) {
                return Boolean(value) || null;
              }
              return value;
            }
          });
          console.log(json, 'json.datatata');
          
          if (json.errors?.length === 0) {
            console.log(json.data, 'json.data');
            setJSONData(json.data);
          } else {
            dispatch(create({
              setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
              value: { message: mls(json.errors[0]?.message), time: 5, isActive: true },
            }));
          }
      }
      reader.readAsText(acceptedFiles[0]);
    },
    accept: {
      'text/csv': ['.csv'],
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {Math.ceil(file.size / 1024)} KB
    </li>
  ));

  return (
    <Modal show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{mls('uploadCSV')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className='my-5 h4'>
            {
              (files?.length > 0) ? (
                <div>
                  {mls('CSV File to Upload:')}
                  <br />
                  <div className='h6'>
                    {files}
                  </div>
                </div>
              )
              : (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>
                    {mls('Drag & drop some files here, or click to select files')}
                  </p>
                </div>
              )
            }
            
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal(false)}>
            {mls('Cancel')}
          </Button>
          <Button variant="primary" onClick={() => {
              if (jsonData.length > 0) {
                console.log('uploadingCSV');
                
                uploadCSV(jsonData);
              }
              console.log(jsonData, 'jsonDatajsonData');
              
              closeModal(false);
            }}
          >
            {mls('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default CSVUpload;
