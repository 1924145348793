import useStoreSchema from 'commons/useStoreSchema';
import { mls } from 'lib/multilanguagesupport';
import { FC } from 'react';
// import { useLocation } from 'react-router-dom';
import reduxConstants from 'redux/reduxConstants';
import { AsideMenu } from '_metronic/layout/components/aside/AsideMenu';
import FetchPlugins from './FetchPlugins';

type Props = {
  asideMenuCSSClasses: any,
  appData: any,
};

const PluginSideNavigation: FC<Props> = ({ asideMenuCSSClasses, appData }) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const pluginsSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.PLUGINS_SCHEMA);
  // const location = useLocation();

  if (appSchema.app?.projectPlugins instanceof Array && (!pluginsSchema || !pluginsSchema[appSchema.app?.projectPlugins?.[0]?.pluginID])) {
    FetchPlugins(appSchema.app?.projectPlugins, appSchema.app.appID);
    return <></>;
  }

  if (appSchema.app?.projectPlugins instanceof Array) {
    
    const screenGroup = appSchema.app?.projectPlugins.map(
      (pluginObj: { pluginID: string  }) => (pluginsSchema[pluginObj.pluginID]?.pluginNavDetails)
    );
    const projects = [{
      name: mls('Plugins'),
      role: [''],
      icon: '',
      screenGroup,
    }]
    
    return (
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={asideMenuCSSClasses} appData={{ ...appData, projects }} routes={{ appID: appSchema.app.appID }} urlType="plugins" />
      </div>
    )
  }
  
  return <></>;
}

export default PluginSideNavigation;
