import * as Yup from 'yup';

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  OTP: Yup.string(),
});

export const passwordSchema = Yup.object().shape({
  setPassword: Yup.string()
    .required('Please enter a password')
    .min(8, 'Password too short')
    .test('Password is strong', 'Password too weak', (value, context) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSymbole = /[^A-Za-z0-9]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 4;
      const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
      conditions.forEach((condition) => (condition ? validConditions++ : null));
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('setPassword'), null], 'Passwords must match'),
});