/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { IApp, IRoutes } from 'testData/DataModels'
import { mls } from 'lib/multilanguagesupport';

type Props = {
  appData: IApp,
  routes: IRoutes,
  urlType?: string,
}


export const AsideMenuMain: React.FC<Props> = ({ appData, routes, urlType }) => (
  <>
    {
      (appData.projects || []).map((project) => (
        <Fragment key={project.name}>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{mls(project.name)}</span>
            </div>
          </div>
          {
            (project.screenGroup || []).map((screenGroup) => (
              <AsideMenuItemWithSub
                key={screenGroup.name}
                to={`/${routes.appID}/${urlType || 'app'}/${project.name}/${screenGroup.id || screenGroup.name}`}
                title={mls(screenGroup.name)}
                fontIcon={screenGroup.icon}
                // icon='/media/icons/duotune/general/gen022.svg'
                hasBullet={screenGroup.icon == null}
              >
                {
                  (screenGroup.screen || []).map((screen) => (<AsideMenuItem
                    to={`/${routes.appID}/${urlType || 'app'}/${project.name}/${screenGroup.id || screenGroup.name}/${screen.screenID}`}
                    key={screen.screenID}
                    title={mls(screen.screenName)}
                    hasBullet={screen.icon == null}
                    fontIcon={screen.icon}
                  />))
                }
              </AsideMenuItemWithSub >
            ))
          }
        </Fragment>
      ))
    }
  </>
);
