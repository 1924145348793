import { useDispatch } from 'react-redux';
import reduxConstants from 'redux/reduxConstants';
import { create } from 'redux/stateSlice';
import pluginsConstants from './pluginsConstants';
import { supistaAuthApiPost } from 'lib/server-connection/connections';

function FetchPlugins(projectPlugins: Array<any>, appID: string) {
  const dispatch = useDispatch();
  projectPlugins.forEach(async (pluginObj: { pluginID: string; pluginType: string }) => {
    const pluginSchema = await supistaAuthApiPost(
      `plugins/${appID}${(pluginsConstants.pluginData as any)[pluginObj.pluginType]?.fetchURL}`,
      {},
      'GET'
    );
    if (!pluginSchema?.error) {
      dispatch(
        create({
          setPath: `${reduxConstants.config.PLUGINS_SCHEMA}.${pluginObj.pluginID}`,
          value: (pluginsConstants.pluginData as any)[pluginObj.pluginType]?.parseResponse?.(
            pluginSchema,
            pluginObj
          ),
        })
      );
    }
  });
}

export default FetchPlugins;
