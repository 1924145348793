import { createSlice } from '@reduxjs/toolkit';
import set from 'lodash.set';
import { storeData, retrieveData } from '../commons/localStorage';
import reduxConstants from './reduxConstants';

// const { storeData, retrieveData } = localStorage;
const initialState = retrieveData(reduxConstants.STORE_NAME) || {
  [reduxConstants.config.LOGIN]: {},
  [reduxConstants.config.APP_STATE]: {},
};

export const configSlice = createSlice({
  name: reduxConstants.STORE_NAME,
  initialState,
  reducers: {
    [reduxConstants.actions.CREATE]: (state, actions) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      set(state, actions.payload.setPath, actions.payload.value);
      storeData(reduxConstants.STORE_NAME, state);
    },
    [reduxConstants.actions.update]: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.value = 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { create, update } = configSlice.actions;

export default configSlice.reducer;
