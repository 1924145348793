import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Checkbox, Typography, FormHelperText } from '@mui/material';
import './MultipleCheckbox.scss';
import { validate } from 'lib/utils-library';

const MultipleCheckbox = ({
  d3Data,
  items,
  value = {},
  onChange = () => {},
  onBlur = () => {},
  err = '',
}) => {
  // const [value, setValue] = useState(value || d3Data.defaultValue || {});
  const [error, setError] = useState(err || null);

  // useEffect(() => {
  //   setValue(value || d3Data.defaultValue || {})
  // }, [d3Data.defaultValue]);
  const handleChange = (newVal, forItem) => {
    let obj1 = value;
    obj1[forItem] = newVal;
    // setValue(obj1);
    onChange(newVal);
    onBlur(newVal);
    const newError = validate(newVal, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };
  const handleBlur = (newVal) => {
    onBlur(newVal);
    const newError = validate(newVal, d3Data.validation);
    if (newError) {
      setError(newError.message);
    }
  };
  return (
    <FormControl error={Boolean(err) !== false || error != null}>
      {items.map((item, idx) => (
        <label key={idx} className='checkbox_label'>
          <Typography>
            <Checkbox
              onChange={(e) => handleChange(e.target.checked, item)}
              onBlur={(e) => handleBlur(e.target.value)}
              value={value[item]}
            />
            {item}
          </Typography>
        </label>
      ))}
      <FormHelperText>{err || error}</FormHelperText>
    </FormControl>
  );
};

export default MultipleCheckbox;

MultipleCheckbox.propTypes = {
  d3Data: PropTypes.shape({
    key: PropTypes.string,
    validation: PropTypes.array,
  }).isRequired,
  value: PropTypes.object,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

MultipleCheckbox.defaultProps = {
  onBlur: () => null,
  value: {},
  err: '',
};
