import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { LocalizationProvider, DateTimePicker as DTp } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { validate } from 'lib/utils-library';

const DateTimePicker = ({ d3Data, value = '', onChange = () => {}, err = '' }) => {
  // const [value, setValue] = useState(value || d3Data.defaultValue || '');
  const [error, setError] = useState(err || null);

  // useEffect(() => {
  //   setValue(value || d3Data.defaultValue || '')
  // }, [d3Data.defaultValue]);
  const handleChange = (newVal) => {
    // setValue(newVal);
    onChange(newVal);
    if (Object.prototype.toString.call(newVal) === '[object Date]') {
      if (isNaN(newVal.getTime())) {
        setError('Date is not valid');
      } else {
        setError(null);
      }
    }
    const newError = validate(newVal, d3Data.validation);
    if (newError) {
      setError(newError.message);
    }
  };
  const handleClose = (newVal) => {
    if (Object.prototype.toString.call(value) === '[object Date]') {
      if (isNaN(value.getTime())) {
        setError('Date is not valid');
      } else {
        setError(null);
      }
    }
    const newError = validate(value, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DTp
        label={d3Data.name}
        value={value}
        onChange={(e) => handleChange(e)}
        onClose={handleClose}
        onAccept={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={err || error}
            error={Boolean(err) !== false || error != null}
            onBlur={handleClose}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;

DateTimePicker.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    validation: PropTypes.array,
  }).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  err: PropTypes.string,
};

DateTimePicker.defaultProps = {
  value: '',
  err: '',
};
