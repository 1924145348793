const langList = {
  'ja': 'Japanese',
  'en': 'English',
};

function getDeviceLanguage() {
  const lang = navigator.language || navigator.userLanguage;
  return langList[lang.split('-')[0]];
}

export default getDeviceLanguage;
