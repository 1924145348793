import en from './EN';
import jp from './JP';
import BackendEN from './backend/EN';
import BackendJP from './backend/JP';
import DashboardEN from './dashboard/EN';
import DashboardJP from './dashboard/JP';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import getDeviceLanguage from './getDeviceLanguage';
import AnalyticsEN from './analytics/EN';
import AnalyticsJP from './analytics/JP';
import CampaignEN from './campaigns/EN';
import CampaignJP from './campaigns/JP';

localStorage.setItem('__d3__languageUpdate', 'true');

const Japanese = { ...jp, ...BackendJP, ...DashboardJP, ...AnalyticsJP, ...CampaignJP };
const English = { ...en, ...BackendEN, ...DashboardEN, ...AnalyticsEN, ...CampaignEN };
const languages = {
  English,
  Japanese,
};

const defaultLang = localStorage.getItem('__d3__defaultLanguage');
const lang = localStorage.getItem('__d3__language') || getDeviceLanguage() || defaultLang;

let appMLS;
function mls(text) {
  if (lang === 'English') {
    appMLS = {};
  } else if ((lang && !appMLS) || localStorage.getItem('__d3__languageUpdate') === 'true') {
    appMLS = JSON.parse(localStorage.getItem('Supista_APP:config_store'))?.[
      reduxConstants.config.APP_MLS
    ]?.dict;
    if (appMLS) {
      localStorage.setItem('__d3__languageUpdate', 'false');
    }
  }
  return (
    appMLS?.[text] ||
    languages[lang]?.[text] ||
    languages[defaultLang]?.[text] ||
    English[text] ||
    text
  );
}

export { mls };
