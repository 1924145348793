import useStoreSchema from 'commons/useStoreSchema';
import AgGridWrapper from 'lib/ag-grid-table';
// import { mls } from 'lib/multilanguagesupport';
import reduxConstants from 'redux/reduxConstants';
import getComponentSchema from 'screenComponents/getComponentSchema';

const HasMany = ({ relationSchema, dataTableSchema, componentID, parentRowID }) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const userSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.USER_SCHEMA);
  const appID = appSchema?.app?.appID;
  const { allRoles } = userSchema;
  const teamID = allRoles?.[appID]?.erpRole || [];

  const { component, accessSchema, buttonSchema } = getComponentSchema(
    appSchema,
    componentID,
    teamID
  );
  const actionButtons =
    component?.codeCustomization?.[dataTableSchema?.tableID]?.actionButton || {};
  return (
    <AgGridWrapper
      key={componentID}
      component={component}
      tableSchema={dataTableSchema}
      actionButtons={actionButtons}
      accessSchema={accessSchema}
      buttonSchema={buttonSchema}
      parentScreen={false}
      relationSchema={relationSchema}
      parentRowID={parentRowID}
    />
  );
};

export default HasMany;
