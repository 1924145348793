import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider, DatePicker as Dp } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { validate } from 'lib/utils-library';
import ErrorToolTip from '../../ErrorTooltip';

export default forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState(null);
  const handleChange = (newVal) => {
    setValue(newVal);
    if (Object.prototype.toString.call(newVal) === '[object Date]') {
      if (Number.isNaN(newVal.getTime())) {
        setError('Date is not valid');
      } else {
        setError(null);
      }
    }
    const newError = validate(newVal, props.columnData.validation);
    if (newError) {
      setError(newError.message);
    }
  };
  const handleClose = () => {
    if (Object.prototype.toString.call(value) === '[object Date]') {
      if (Number.isNaN(value.getTime())) {
        setError('Date is not valid');
      } else {
        setError(null);
      }
    }
    const newError = validate(value, props.columnData.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    afterGuiAttached: () => {},
  }));

  return (
    <ErrorToolTip
      open={Boolean(error)}
      title={error == null ? '' : error}
      arrow
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Dp
          label={props.columnData.name}
          value={value}
          onChange={(e) => handleChange(e)}
          onClose={handleClose}
          onAccept={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              error={Boolean(error)}
              onBlur={handleClose}
            />
          )}
        />
      </LocalizationProvider>
    </ErrorToolTip>
  );
});
