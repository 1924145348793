import { mls } from 'lib/multilanguagesupport';
import getAppID from 'lib/server-connection/getAppID';
import { useLocation } from 'react-router-dom';

const appURL = 'https://test.supista.com';
const CellButton = ({
  valueFormatted,
  value,
  setValue,
  colDef: {
    cellEditorParams: { columnData, componentID, parentScreen },
    ...colDef
  },
  data,
  ...props
}) => {
  const { pathname } = useLocation();
  const appID = getAppID(pathname);
  if (!value) return '';

  // checking for url in the value. If url then it directly opens the file
  const urlPattern = /^https?:\/\/[^\s]+/;
  let url;
  if (urlPattern.test(value)) {
    url = value;
  } else url = `${appURL}/api/app/${appID}/fileDownload/${componentID}?data={"fileID":"${value}"}`;

  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <div className='d-flex align-items-center'>
      <button className='btn btn-sm btn-light-primary mt-0' onClick={handleClick}>
        {mls('Download File')}
      </button>
    </div>
  );
};
export default CellButton;
