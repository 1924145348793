import AgGridWrapper from 'lib/ag-grid-table';

const componentType = {
  table: AgGridWrapper,
  userRegistration: AgGridWrapper,
  'CRUD Component': AgGridWrapper,
  /**
   * @todo
   * Here you can add different View components like
   *  1. Calendar View
   *  2. Kanban View
   *  3. Card View
   *  4. MDX Files
   *  5. Form View
   */
};

export default componentType;
