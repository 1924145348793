const reduxConstants = {
  STORE_NAME: 'config_store',
  actions: {
    CREATE: 'create',
    UPDATE: 'update',
  },
  config: {
    LOGIN: 'login',
    APP_SCHEMA: 'appSchema',
    USER_SCHEMA: 'userSchema',
    APP_MLS: 'appMLS',
    USER_ACCESS_SCHEMA: 'userAccessSchema',
    PLUGINS_SCHEMA: 'pluginsSchema',
    APP_STATUS: {
      TOAST: 'appStatus.toast',
      ERROR_TOAST: 'appStatus.errorToast',
      LOADING: 'appStatus.loading',
    },
  },
}

export default reduxConstants
