import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {useState, useEffect} from 'react'
import {mls} from 'lib/multilanguagesupport'

type Props = {
  value: any
  setValue: Function
}
const days = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su']
// prettier-ignore
const dates = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31',]

const inaButtons = 'fw-bold rounded  w-40px h-40px border-0 bg-secondary align-middle text-gray-600'
const aButtons = 'fw-bold border-0 w-40px h-40px  text-primary rounded bg-primary bg-opacity-10'

const Step4: FC<Props> = ({value, setValue}) => {
  const [repetition, updateRepetition] = useState<Array<string | number>>([])
  const [flag, updateFlag] = useState(0)

  const clickedButtonHandler = (e: any) => {
    var stat = false
    const repeatOn = e.target.value

    if (repetition.includes(repeatOn)) {
      const index = repetition.indexOf(repeatOn)
      if (index > -1) {
        stat = true
        // console.log('repeatOnrepeatOn adding', repeatOn);
        
        repetition.splice(index, 1)
        updateRepetition([...repetition])
      }
    } else {
      repetition.push(repeatOn);
      updateRepetition([...repetition])
    }

    // console.log(repeatOn, value.repeatOn, repetition, 'repeatOnrepeatOn');
    
    setValue({...value, repeatOn: [...repetition]})
  }

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>{mls('Repetition')}</h2>

        <div className='text-gray-400 fw-bold fs-6'>{mls('Send notification at')}: </div>
      </div>
      <div className='d-flex flex-wrap pb-5'>
        <Field
          onClick={(e: any) => {
            updateFlag(0)
            setValue({...value, repetitionType: e.target.value, repeatOn: []})
            updateRepetition([])
          }}
          className='form-check-input h-15px w-15px mt-1'
          type='radio'
          name='repetition-onlyOnce'
          value='onlyOnce'
          checked={value.repetitionType === 'onlyOnce'}
        />

        <label className='form-check-label text-gray-500 fw-bold fs-5 px-5'>{mls('onlyOnce')}</label>
      </div>
      <div className='d-flex flex-wrap pb-5'>
        <Field
          onClick={(e: any) => {
            updateFlag(0)
            setValue({...value, repetitionType: e.target.value, repeatOn: []})
            updateRepetition([])
          }}
          className='form-check-input h-15px w-15px mt-1'
          type='radio'
          name='repetition-daily'
          value='daily'
          checked={value.repetitionType === 'daily'}
        />

        <label className='form-check-label text-gray-500 fw-bold fs-5 px-5'>{mls('Daily')}</label>
      </div>
      <div className='d-flex flex-wrap pb-5'>
        <Field
          onClick={(e: any) => {
            updateFlag(1)
            setValue({...value, repetitionType: e.target.value, repeatOn: []})
            updateRepetition([])
          }}
          className='form-check-input h-15px w-15px mt-1'
          type='radio'
          name='repetition-weekly'
          value='weekly'
          checked={value.repetitionType === 'weekly'}
        />

        <label className='form-check-label text-gray-500 fw-bold fs-5 px-5'>{mls('weekly')}</label>
      </div>

      {value.repetitionType === 'weekly' && (
        <div className='d-flex flex-wrap mb-5'>
          {days.map((day) => (
            <span className='col-1 px-10 pb-5'>
              <button
                value={day}
                className={repetition.includes(day) ? `${aButtons}` : `${inaButtons}`}
                type='button'
                onClick={(e: any) => clickedButtonHandler(e)}
              >
                {mls(day)}
              </button>
            </span>
          ))}
        </div>
      )}

      <div className='d-flex flex-wrap pb-5'>
        <Field
          onClick={(e: any) => {
            updateFlag(2)
            setValue({...value, repetitionType: e.target.value})
          }}
          className='form-check-input h-15px w-15px mt-1'
          type='radio'
          name='repetition-monthly'
          value='monthly'
          checked={value.repetitionType === 'monthly'}
        />

        <label className='form-check-label text-gray-500 fw-bold fs-5 px-5 pb-5'>
          {mls('monthly')}
        </label>

        {value.repetitionType === 'monthly' && (
          <div className='d-flex flex-wrap mb-5'>
            {dates.map((date) => (
              <span className='col-1 px-10 pb-5'>
                <button
                  value={date}
                  className={repetition.includes(date) ? `${aButtons}` : `${inaButtons}`}
                  type='button'
                  onClick={(e: any) => clickedButtonHandler(e)}
                >
                  {mls(date)}
                </button>
              </span>
            ))}
          </div>
        )}
      </div>

      {flag === 2 && (
        <>
          <div className='d-flex flex-wrap pb-4'>
            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input h-15px w-15px'
                type='checkbox'
                name='secondSaturday'
                value='secondSaturday'
                onClick={(e: any) => {
                  e.target.checked
                    ? setValue({...value, secondSaturday: true})
                    : setValue({...value, secondSaturday: null})
                }}
              />
            </span>

            <label className='form-check-label text-gray-500 fw-bold fs-5 px-3'>
              {mls('Second Saturday')}
            </label>
          </div>
          <div className='d-flex flex-wrap pb-4'>
            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input h-15px w-15px'
                type='checkbox'
                name='monthEnd'
                value='monthEnd'
                onClick={(e: any) => {
                  e.target.checked
                    ? setValue({...value, monthEnd: true})
                    : setValue({...value, monthEnd: null})
                }}
              />
            </span>

            <label className='form-check-label text-gray-500 fw-bold fs-5 px-3'>
              {mls('Month End')}
            </label>
          </div>
          <div className='d-flex flex-wrap'>
            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input h-15px w-15px'
                type='checkbox'
                name='lastWorkingDay'
                value='lastWorkingDay'
                onClick={(e: any) => {
                  e.target.checked
                    ? setValue({...value, lastWorkingDay: true})
                    : setValue({...value, lastWorkingDay: null})
                }}
              />
            </span>
            <label className='form-check-label text-gray-500 fw-bold fs-5 px-3 mt-3'>
              {mls('Last Working Day')} ({mls('week ends on')}):
            </label>

            <Field
              as='select'
              name='weekEnd'
              className='form-select form-select-md form-select-solid fw-bold text-gray-400 w-200px'
              onClick={(e: any) => {
                setValue({...value, weekEnd: e.target.value})
              }}
            >
              <option>{mls('On Monday')}</option>
              <option>{mls('On Tuesday')}</option>
              <option>{mls('On Wednesday')}</option>
              <option>{mls('On Thursday')}</option>
              <option>{mls('On Friday')}</option>
              <option>{mls('On Saturday')}</option>
              <option>{mls('On Sunday')}</option>
            </Field>
          </div>
        </>
      )}
    </div>
  )
}

export {Step4}
