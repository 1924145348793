import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MessageHandling from 'screenComponents/MessageHandling';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import Routes from './routing/Routes';
import reduxConstants from 'redux/reduxConstants';
import useStoreSchema from 'commons/useStoreSchema';
import logo from '../media/logo/icon.png';

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appData = { ...appSchema.app, logo: appSchema.app?.logo || logo };

  useEffect(() => {
    const appIcon = document.getElementById('__d3__app-logo-id') as HTMLLinkElement;
    document.title = appData.htmlTitle ? appData.htmlTitle : document.title;
    if (appIcon) {
      appIcon.href = appData.logo;
    }
  }, [appData.htmlTitle, appData.logo]);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <Routes />
            <MessageHandling />
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export { App };
