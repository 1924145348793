import { mls } from 'lib/multilanguagesupport'
import {FC} from 'react'
import Cards from './cards/Cards'
import { GMTToLocal } from './utility'

type Props = {
  notificationList: [
    {
      _id: string,
      campaignGroupID: string
      campaignHeader: string
      campaignBody: string
      campaignImg: string
      campaignTags: Array<string>
      deliveryType: string
      deliverAt: string
      durationStart: number
      durationEnd: number
      repetitionType: string
      repeatOn: Array<string | number>
      filters: Object
      usersReached: number
      successfulExecution: number
      status: string
      campaignCategory: string
    }
  ],
  editCampaign: Function,
  deleteCampaign: Function,
  categories: [string],
}

const CampaignsCardWrapper: FC<Props> = ({categories, notificationList, editCampaign, deleteCampaign }) => {
  const categoriesObj:any = {};
  (categories || []).forEach(category => {
    categoriesObj[category] = [];
  });
  categoriesObj['Uncategorized'] = []; // adding undefined as key for undefined categories

  (notificationList || []).forEach((notification) => {
    categoriesObj[
      categories.includes(notification.campaignCategory)
      ? notification.campaignCategory
      : 'Uncategorized'
    ].push(notification);
  });
  
  return (
    <div className='row'>
      {
        Object.keys(categoriesObj).map((category) => {
          if (categoriesObj[category].length === 0) {
            return '';
          }
          return (<div>
          <div  className='fw-bold text-gray-700 mb-2' style={{fontSize: '16px'}}>
            {mls(category)}
          </div>
          <div className='row'>
          {categoriesObj[category].map((notification:Props["notificationList"][0]) => {
            return (
            <div className='col-lg-4 col-md-6 col-sm-12 pb-4'>
              <Cards
                _id={notification._id}
                icon='/media/svg/brand-logos/disqus.svg'
                badgeColor='info'
                status={notification.status}
                title={notification.campaignHeader}
                repetitionType={notification.repetitionType}
                description={notification.campaignBody}
                date='May 10, 2021'
                durationStart={notification.durationStart}
                durationEnd={notification.durationEnd}
                successfulExecution={notification.successfulExecution}
                repeatOn={notification.repeatOn}
                usersReached={notification.usersReached}
                progress={30}
                deliverAt={GMTToLocal(notification.deliverAt)}
                editCampaign={editCampaign}
                deleteCampaign={deleteCampaign}
              />
            </div>
          )}
          )}
          </div>
        </div>)})
        
      }
      
    </div>
  )
}

export default CampaignsCardWrapper
