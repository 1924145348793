import { GetComponent } from './GetComponent';

export function ManageCreateData({
  component,
  columnSchema,
  // dataRef,
  error,
  handleFormData,
  formData,
  checkFormCustomization,
  isUpdate,
}) {
  const onChange = (columnName) => {
    return (value) => {
      handleFormData(columnName, value);
      // dataRef.current = { ...dataRef.current, [columnName]: value };
    };
  };
  const onBlur = (columnName) => {
    return (value) => {
      handleFormData(columnName, value);
      checkFormCustomization();
      // dataRef.current = { ...dataRef.current, [columnName]: value };
    };
  };
  return (columnSchema || []).map((columnData) => {
    if (!component.roleAccess) {
      return (
        <GetComponent
          key={columnData.columnName}
          component={component}
          columnData={{
            ...columnData,
            // adding default value and disabling if there it's a connected screen column
            // defaultValue: dataRef.current[columnData.columnName] || columnData.defaultValue,
            // disabled: Boolean(connectedScreenParams[columnData.columnName]) || columnData.defaultValue
          }}
          onChange={onChange(columnData.columnName)}
          onBlur={onBlur(columnData.columnName)}
          error={error[columnData.columnName]}
          handleFormData={handleFormData}
          value={formData[columnData.columnName]}
        />
      );
    }
    if (columnData.role?.includes(isUpdate ? 'U' : 'C')) {
      return (
        <GetComponent
          key={columnData.columnName}
          component={component}
          columnData={{
            ...columnData,
            // adding default value and disabling if there it's a connected screen column
            // defaultValue: dataRef.current[columnData.columnName] || columnData.defaultValue,
            // disabled: Boolean(connectedScreenParams[columnData.columnName]) || columnData.defaultValue
          }}
          onChange={onChange(columnData.columnName)}
          onBlur={onBlur(columnData.columnName)}
          error={error[columnData.columnName]}
          handleFormData={handleFormData}
          value={formData[columnData.columnName]}
        />
      );
    }
    return <></>;
  });
}
