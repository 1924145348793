import { mls } from "lib/multilanguagesupport";
import { useHistory, useLocation } from "react-router-dom";
import HasMany from "./relatedScreens/HasMany";
import HasOne from "./relatedScreens/HasOne";
import BelongsToMany from "./relatedScreens/BelongsToMany";
import { useState } from "react";

const RelatedScreens = {
  hasMany: HasMany,
  hasOne: HasOne,
  belongsToMany: BelongsToMany,
};

const ConnectedScreenWrapper = ({ connectedScreenGroup, componentID, rowID, currentScreenGroupID }) => {
  // const { search } = useLocation();
  // const query = new URLSearchParams(search);
  // const [currentScreenGroupID] = useState(() => {
  //   let currentScreenID = query.get('__d3__currentScreenID');
  //   Object.keys(connectedScreenGroup).forEach((relationID) => {
  //     if(!currentScreenID && connectedScreenGroup[relationID].relationSchema.type !== 'belongsTo') {
  //       currentScreenID = relationID;
  //     }
  //   });
  //   return currentScreenID;
  // });
  // const goMainScreen = () => {
  //   const path = history.location.pathname.split('/');
  //   path.pop();
  //   // console.log(path.join('/'), 'history.location.pathname');
  //   history.push({
  //     pathname: `${path.join('/')}`
  //   });
  // };
  if (!currentScreenGroupID) return <></>;
  console.log(connectedScreenGroup, currentScreenGroupID, 'connectedScreenGroup')
  if (!connectedScreenGroup[currentScreenGroupID]?.relationSchema?.type) {
    return <></>;
  }
  const RelatedScreenType = RelatedScreens[connectedScreenGroup[currentScreenGroupID].relationSchema?.type];
  return (
      <div className='mx-auto px-5'>
        <div className="pt-0 pb-10">
          {
            RelatedScreenType && <RelatedScreenType
              relationSchema={connectedScreenGroup[currentScreenGroupID].relationSchema}
              dataTableSchema={connectedScreenGroup[currentScreenGroupID].dataTableSchema}
              componentID={componentID}
              parentRowID={rowID}
            />
          }
        </div>
      </div>
  )
}

export default ConnectedScreenWrapper;
