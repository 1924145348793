import React, { useState } from 'react';
import clsx from 'clsx';
// import { toAbsoluteUrl } from '../../../helpers';
import { mls } from 'lib/multilanguagesupport';
import enFlag from 'media/flags/united-states.svg';
import china from 'media/flags/china.svg';
import spain from 'media/flags/spain.svg';
import japan from 'media/flags/japan.svg';
import germany from 'media/flags/germany.svg';
import france from 'media/flags/france.svg';
const languageDict = {
  English: {
    lang: 'en',
    name: 'English',
    flag: enFlag,
  },
  Mandarin: {
    lang: 'zh',
    name: 'Mandarin',
    flag: china
  },
  Spanish: {
    lang: 'es',
    name: 'Spanish',
    flag: spain
  },
  Japanese: {
    lang: 'ja',
    name: 'Japanese',
    flag: japan
  },
  German: {
    lang: 'de',
    name: 'German',
    flag: germany
  },
  French: {
    lang: 'fr',
    name: 'French',
    flag: france
  },
};

function SupistaLanguages() {
  const [currentLanguage, setLanguage] = useState(localStorage.getItem('__d3__language') || localStorage.getItem('__d3__defaultLanguage') || 'English');
  const [open, setOpen] = useState(false);
  const [languages] = useState(() => {
    const allLanguages = localStorage.getItem('__d3__allLanguages');
    if (allLanguages && typeof allLanguages === 'object') {
      return JSON.parse(allLanguages);
    }
    else return ['English'];
  });
  console.log(languages, 'languageslanguages');
  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a className='menu-link px-5' onClick={() => setOpen(!open)}>
        <span className='menu-title position-relative'>
          {mls('Language')}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={languageDict[currentLanguage]?.flag}
              alt='Country Flag'
            />
          </span>
        </span>
      </a>

      {open && <div className='w-250px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l}
            onClick={() => {
              setLanguage(l);
              localStorage.setItem('__d3__language', l);
              setTimeout(() => {
                window.location.reload();
              }, 0);
            }}
          >
            <a
              className={clsx('menu-link d-flex px-5', {
                active: l === currentLanguage,
              })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={(languageDict[l]?.flag)} alt='Flag' />
              </span>
              {l}
            </a>
          </div>
        ))}
      </div>}
    </div>
  )
}

export default SupistaLanguages;
