import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {StepperComponent} from '_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {ICreateAccount, createAccountSchemas, inits} from './CreateAccountWizardHelper'
import {mls} from 'lib/multilanguagesupport'

type Props = {
  addNewNotification: Function
  schema: any
  getOptions: Function,
  updateCampaign: any,
}

const defaultCampaignData = {
  status: 'Active',
  deliverAt: '08:30',
  deliveryType: 'Static Time',
  repetitionType: 'onlyOnce',
};

const Vertical: FC<Props> = ({addNewNotification, schema, getOptions, updateCampaign}) => {
  const [value, setVal] = useState(() => {
    return (updateCampaign || { ...defaultCampaignData, campaignCategory: schema.campaignCategory?.[0] || '' });
  })
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const setValue = (val: any) => {
    // console.log(val, 'val, val')

    setVal(val)
  }
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const submitValues = async () => {
    if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber!) {
      addNewNotification(value)
    }
  }

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <div className='d-none d-sm-none d-md-none d-lg-block d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        <div className='px-6 px-lg-10 px-xxl-15 py-20'>
          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>1</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>{mls('Create Campaign')}</h3>

                <div className='stepper-desc fw-bold fs-8'>
                  {mls('Name your campaign & initial details')}
                </div>
              </div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>2</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>{mls('Target User')}</h3>
                <div className='stepper-desc fw-bold fs-8'>
                  {mls('The basic device information')}
                </div>
              </div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>3</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>{mls('Delivery Time')}</h3>
                <div className='stepper-desc fw-bold fs-8'>{mls('Creation category etc.')}</div>
              </div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>4</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>{mls('Repetition')}</h3>
                <div className='stepper-desc fw-bold fs-8'>{mls('Category of user group')}</div>
              </div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>5</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>{mls('Review')}</h3>
                <div className='stepper-desc fw-bold fs-8'>{mls('Filter based of Usage')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {() => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 value={value} setValue={setValue} schema={schema} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 value={value} setValue={setValue} schema={schema} getOptions={getOptions} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 value={value} setValue={setValue} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 value={value} setValue={setValue} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 value={value} setValue={setValue} />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    {mls('Back')}
                  </button>
                </div>

                <div>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary me-3'
                    onClick={submitValues}
                  >
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex !==
                        stepper.current?.totatStepsNumber! - 1 && mls('Continue')}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 1 && mls('Submit')}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {Vertical}
