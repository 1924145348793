/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { mls } from 'lib/multilanguagesupport';

export function CompanyDetails({handleSubmit, questionAnswer, heading, loading}) {
  const [values, setValues] = useState(questionAnswer);
  console.log(questionAnswer, 'questionAnswer')
  return (
    <>
      <div
        className='form w-100'
        noValidate
        id=''
        // onSubmit={handleSubmit}
      >
        {/* begin::Heading */}
        <div className='mb-10 text-center'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{mls(heading)}</h1>
          {/* end::Title */}
        </div>

        {
          questionAnswer.map((QnA, index) => (
            <div className='fv-row mb-5'>
              {console.log(values, index)}
              <label className='form-label fw-bolder text-dark fs-6'>{mls(QnA.Question)}</label>
              <textarea
              rows='2'
                autoComplete='off'
                onChange={(e) => {
                  values[index].Answer = e.target.value;
                  setValues([...values]);
                }}
                onBlur={(e) => {
                  values[index].Answer = e.target.value;
                  setValues([...values]);
                }}
                className='form-control form-control-lg form-control-solid'
                value={values[index].Answer}
              />
              
            </div>)
          )
        }
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            // disabled={!formik.values.acceptTerms || !nameAvailable}
            onClick={() => {
              handleSubmit(values);
            }}
          >
            {!loading && <span className='indicator-label'>{mls('Submit')}</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {mls('Please wait...')}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {/* <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
            
          >
            {mls('Cancel')}
          </button> */}
        </div>
      </div>
    </>
  );
}
