module.exports = {
  'No App ID Defined': 'アプリIDが定義されていません。',
  'Can not create new location to store your Files. Please try again later.': 'ファイルを保存するため新しい場所を作成できません。 後でもう一度やり直してください。',
  'New location to store your Files have been Created. Please try again to store files now :)': 'ファイルを保存するための新しい場所が作成されました。 今すぐファイルを保存してみてください。)',
  'BAD REQUEST': 'あなたは良くない要求をしています！ このウェブページまたはアプリをリロードしてください。 この問題が解決しない場合は、スクリーンショットと複製手順を添えてsupport@supista.comまでメールでお問い合わせください。',
  UNAUTHORIZED: 'このリソースへのアクセスは許可されていません。 エラーと思われる場合は、管理部門に連絡して許可を与えてください。',
  FORBIDDEN: 'このリクエストにアクセスすることはできません。',
  'INTERNAL SERVER ERROR': 'サーバーで厄介なエラーが発生しました。 この問題をできるだけ早く軽減するよう取り組んでいます。 しばらくしてからもう一度お試しください。 このエラーが続く場合は、スクリーンショットと複製手順を添えてsupport@supista.comまでご連絡ください。',
  'SERVICE UNAVAILABLE': 'お探しのサービスは現在ご利用いただけません。しばらくしてからもう一度お試しください。',
  'NOT FOUND': '探しているリソースが見つかりません。',
  'No app ID Defined!': 'アプリIDが定義されていません。',
  'Can not parse the JSON request. Make sure the request is in JSON format.': 'JSONリクエストを解析できません。 リクエストがJSON形式であることを確認してください。',
  'Company Name is a Required Field.': '会社名は必須項目です。',
  'Company Name must be at least 3 characters': '会社名は3文字以上である必要があります。',
  'Email ID is a Required Field.': 'メールアドレスは必須項目です。',
  'Invalid Email ID.': 'メールアドレスは無効です。',
  'Password is a Required Field.': 'パスワードは必須項目です。',
  'Please enter a strong password of at least 8 digits long.': '8桁以上の強力なパスワードを入力してください。',
  'Some error occured': 'エラーが発生しました。',
  'App Name must be a mixture of English lowercase Alphabets and Numbers!': 'アプリ名は、英語の小文字のアルファベットと数字を組み合わせたものにする必要があります。',
  'App Name must be at least 3 characters': 'アプリ名は3文字以上である必要があります。',
  'App Name is a Required Field.': 'アプリ名は必須項目です。',
  'Country is a Required Field.': '国は必須項目です。',
  'App Name must be unique!': 'アプリ名は一意である必要があります。',
  UnexpectedError: '予期しないエラーが発生しました！ しばらくしてからお試しください。',
  'Your email ID has already been registered! Please Login to create a new App.': 'メールアドレスはすでに登録されています！ ログインして新しいアプリを作成してください。',
  'Email Id or Password did not match!': 'メールアドレスまたはパスワードが一致しませんでした。',
  'You will receive an Email containing the OTP to your registered Email ID.': '登録したメールアドレスにワンタイムパスワードを含むメールが届きます。',
  'Could not verify OTP.': 'ワンタイムパスワードを確認できませんでした。',
  'OTP Expired! Please try again later.': 'ワンタイムパスワードの有効期限が切れました！ 後でもう一度やり直してください。',
  'New Password is a Required Field.': '新しいパスワードは必須項目です。',
  'Confirm New Password is a Required Field.': '新しいパスワードが必須項目であることを確認します。',
  'Passwords did not Match.': 'パスワードが間違っています。',
  'Could not update Password. Please re-verify OTP.': 'ワンタイムパスワードが間違っています。確認してください。',
  'Password Updated Successfully.': 'パスワードが正常に更新されました。',
  'User is not logged in. Please login to update password or click on forgot password link in signup page.': 'ユーザーはログインしていません。ログインしてパスワードを更新するか、サインアップページの[パスワードを忘れた場合]リンク・ボタンをクリックしてください。',
  'User Not Logged in.': 'ユーザーがログインしていません。',
  'secretLoginKey is a Required Field.': 'シークレットログインキーは必須項目です。',
  'ITStakeholder must be an array.': '管理部の利害関係者はアレイでなければなりません。',
  'Role must be an array.': 'ロールは配列でなければなりません。',
  'Schema Object updated successfully.': 'スキーマオブジェクトが正常に更新されました。',
  'Invalid App ID.': 'アプリIDが無効です。',
  'App successfully published.': 'アプリが正常に公開されました。',
  'App is being updated| Please try again letter': 'アプリは更新中です。後でもう一度やり直してください。',

  // version 2.
  'No Data found to update': '更新するデータが見つかりません',
  'No such user found.': 'そのようなユーザーは見つかりませんでした',
  InvalidResourceUpdate: 'アクセス権のない 1つ以上のデーターを更新しようとしています!',
  'Please provide a columnName to find distinct values!': '個別の値を見つけるために列名を指定してください!',
  'Current Password is a Required Field.': '現在のパスワードは必須項目です',
  'Email Id does not exist!': 'メールアドレスが存在しません!',
  'Current Password did not match!': '現在のパスワードが一致しませんでした!',
  'Key of item not defined! Can not delete this item.': '項目のキーが定義されていません。このデーターを削除できません',
  'Your email ID has already been registered! Please Login.': 'このメールアドレスは既にスマホカルテIDとして登録されています。ログインしてご利用ください。',
  'You don\'t have sufficient rights to perform this action!': 'このアクションを実行するための十分な権限はありません。',
  'OTP cannot be sent as your email address is not registered. Please register/sign-up in the app first.': 'メールアドレスが登録されていないため、ワンタイムパスワードを送信できません。 始めにアプリに登録/サインアップしてください。',
};
