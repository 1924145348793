import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validate } from 'lib/utils-library';
import { Spinner } from 'react-bootstrap-v5'; // Using react-bootstrap Spinner
import useOperations from 'lib/reusable-components/hooks/fetchERPdata/useOperations';
import { mls } from 'lib/multilanguagesupport';
const appURL = 'https://test.supista.com';
const File = ({
  d3Data,
  value = '',
  onChange = () => {},
  onBlur = () => {},
  err = '',
  component,
}) => {
  // const [value, setValue] = useState(value || d3Data.defaultValue || '');
  const [error, setError] = useState(err || null);
  const [loading, setLoading] = useState(false); // State to show loading spinner
  const operations = useOperations(component);
  const { appID, componentID } = component;

  // useEffect(() => {
  //   setValue(value || d3Data.defaultValue || '');
  // }, [d3Data.defaultValue]);

  const handleValidation = (newVal) => {
    const newError = validate(newVal, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setLoading(true); // Start showing the loading spinner
      try {
        // file upload to the server and getting the file URL
        const fileUploadRes = await operations.uploadFile(file); // uploadFileToServer should be implemented to handle actual file upload
        if (fileUploadRes?.__d3__error)
          setError(fileUploadRes?.message || mls('Could not upload the File!'));
        else if (fileUploadRes?.data?.fileID) {
          // setValue(fileUploadRes?.data?.fileID);
          onChange(fileUploadRes?.data?.fileID);
        } else setError(mls('File upload failed'));
      } catch (uploadError) {
        setError(mls('File upload failed'));
      } finally {
        setLoading(false); // Stop showing the loading spinner
      }
    }
  };

  // const handleBlur = (newVal) => {
  //   onBlur(newVal);
  //   handleValidation(newVal);
  // };

  return (
    <div className='row'>
      <label htmlFor={d3Data.columnName} className='col-sm-4 col-lg-3 col-form-label'>
        {d3Data.name}
      </label>
      <div className='col-sm-8 col-lg-9' style={{ height: '45px' }}>
        {loading ? (
          <div className='d-flex p-2 align-items-center'>
            <Spinner animation='border' role='status'>
              <span className='sr-only'>Uploading...</span>
            </Spinner>
            <span className='px-5'>Uploading...</span>
          </div>
        ) : value ? (
          <a
            href={`${appURL}/api/app/${appID}/fileDownload/${componentID}?data={"fileID":"${value}"}`}
            className='btn btn-primary'
            target='_blank'
            rel='noopener noreferrer'
          >
            Download File
          </a>
        ) : (
          <input
            id={d3Data.columnName}
            className={`form-control ${err || error ? 'is-invalid' : ''}`}
            type='file'
            onChange={handleFileUpload}
            disabled={d3Data.disabled}
          />
        )}
        {(err || error) && <div className='invalid-feedback'>{err || error}</div>}
      </div>
    </div>
  );
};

export default File;

File.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired,
    datatype: PropTypes.string,
    defaultValue: PropTypes.string,
    validation: PropTypes.array,
    disabled: PropTypes.bool,
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

File.defaultProps = {
  value: '',
  onBlur: () => null,
  onChange: () => null,
  err: '',
};
