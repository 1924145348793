import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel, Row, Col } from 'react-bootstrap-v5';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { validate } from 'lib/utils-library';
import enUS from 'date-fns/locale/en-US';
import enIN from 'date-fns/locale/en-IN';
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import ja from 'date-fns/locale/ja';
import 'bootstrap/dist/css/bootstrap.min.css';

const DatePickerComponent = ({
  d3Data,
  value = '',
  onChange = () => {},
  onBlur = () => {},
  err = '',
}) => {
  // const [value, setValue] = useState(value ? new Date(value) : d3Data.defaultValue ? new Date(d3Data.defaultValue) : null);
  const [error, setError] = useState(err || null);
  const [isEditing, setEditing] = useState(false);
  const [locale, setLocale] = useState(enUS);
  const [isLocaleSet, setIsLocal] = useState(null);

  useEffect(() => {
    if (!isLocaleSet && value) {
      const initialValue = value
        ? new Date(value)
        : d3Data.defaultValue
        ? new Date(d3Data.defaultValue)
        : null;
      onChange(initialValue);

      // Detect browser locale and set corresponding locale
      const browserLocale = navigator.language || navigator.userLanguage;
      setLocale(getLocale(browserLocale));
      setIsLocal(true);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, d3Data.defaultValue]);

  const handleValidation = (newVal) => {
    const newError = validate(newVal, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };

  const handleChange = (newVal) => {
    if (newVal instanceof Date && !isNaN(newVal)) {
      // setValue(newVal);
      onChange(newVal);
      handleValidation(newVal);
      setEditing(false);
    } else {
      setError('Invalid date');
    }
  };

  const handleBlur = () => {
    if (!(value instanceof Date) || isNaN(value)) {
      setError('Invalid date');
    } else {
      setError(null);
    }
    handleValidation(value);
    setEditing(false);
    onBlur(value);
  };

  const getLocale = (browserLocale) => {
    switch (browserLocale) {
      case 'fr':
      case 'fr-FR':
        return fr;
      case 'es':
      case 'es-ES':
        return es;
      case 'ja':
      case 'ja-JP':
        return ja;
      case 'en-IN':
        return enIN;
      default:
        return enUS;
    }
  };

  return (
    <FormGroup as={Row} className='mb-3'>
      <FormLabel column sm={4} lg={3}>
        {d3Data.name}
      </FormLabel>
      <Col sm={8} lg={9} style={{ height: '45px' }}>
        {isEditing ? (
          <DatePicker
            selected={value}
            onChange={handleChange}
            onBlur={handleBlur}
            dateFormat='P' // Only show the date part
            locale={locale}
            className={`form-control ${err || error ? 'is-invalid' : ''}`}
            showTimeSelect={false} // Disable time selection
            onClickOutside={handleBlur} // Handle click outside to close the datepicker
            autoFocus // Automatically focus when rendered
          />
        ) : (
          <span
            className='form-control-plaintext'
            style={{
              textDecoration: value ? 'underline dotted' : 'underline dotted',
              cursor: 'pointer',
              color: value ? 'inherit' : '#009ef7',
            }}
            onClick={() => !d3Data.disabled && setEditing(true)}
          >
            {(value && value.toLocaleDateString) ? value.toLocaleDateString(locale) : 'Click to edit'}
          </span>
        )}
        {(err || error) && <div className='invalid-feedback'>{err || error}</div>}
      </Col>
    </FormGroup>
  );
};

export default DatePickerComponent;

DatePickerComponent.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    validation: PropTypes.array,
    disabled: PropTypes.bool,
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

DatePickerComponent.defaultProps = {
  value: '',
  onBlur: () => null,
  onChange: () => null,
  err: '',
};
