import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Switch } from '@mui/material';
import { validate } from 'lib/utils-library';
import styles from './Switch.module.css';
import ErrorToolTip from '../../ErrorTooltip';

export default forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value === null ? false : props.value);
  const [error, setError] = useState(null);

  const handleChange = (val) => {
    setValue(val);
    const newError = validate(val, props.columnData.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
  };

  const handleBlur = (val) => {
    const newError = validate(value, props.columnData.validation);
    if (newError) {
      setError(newError.message);
    }
  };

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    afterGuiAttached: () => {},
  }));

  return (
    <ErrorToolTip
      open={Boolean(error)}
      title={error == null ? '' : error}
      arrow
    >
      <div className={styles.wrapper}>
        <Switch
          checked={value}
          onChange={(e) => handleChange(e.target.checked)}
          onBlur={handleBlur}
        />
      </div>
    </ErrorToolTip>
  );
});
