export function agGridModelToServerFilter(filterModel, columnSchema) {
  const serverFilterModel = {}
  Object.keys(filterModel || {}).forEach((columnID) => {
    if (filterModel[columnID].filter instanceof Array && filterModel[columnID].filter.length > 0) {
      serverFilterModel[columnID] = filterModel[columnID].filter;
    } else if (filterModel[columnID].filterType === 'text') {
      switch (filterModel[columnID].type) {
        case 'contains':
          serverFilterModel[columnID] = { like: `%${filterModel[columnID].filter}%` };
          break;
          case 'notContains':
            serverFilterModel[columnID] = { notLike: `%${filterModel[columnID].filter}%` };
            break;
            case 'equals':
            serverFilterModel[columnID] = filterModel[columnID].filter;
            break;
            case 'notEqual':
            serverFilterModel[columnID] = { ne: filterModel[columnID].filter };
            break;
            case 'startsWith':
            serverFilterModel[columnID] = { startsWith: filterModel[columnID].filter };
            break;
            case 'endsWith':
            serverFilterModel[columnID] = { endsWith: filterModel[columnID].filter };
            break;
            
        default:
          break;
      }
    } else if (filterModel[columnID].filterType === 'number') {
      switch (filterModel[columnID].type) {
        case 'equals':
          serverFilterModel[columnID] = filterModel[columnID].filter;
          break;
        case 'notEqual':
          serverFilterModel[columnID] = { ne: filterModel[columnID].filter };
          break;
        case 'lessThan':
          serverFilterModel[columnID] = { lt: filterModel[columnID].filter };
          break;
        case 'lessThanOrEqual':
          serverFilterModel[columnID] = { lte: filterModel[columnID].filter };
          break;
        case 'greaterThan':
          serverFilterModel[columnID] = { gt: filterModel[columnID].filter };
          break;
        case 'greaterThanOrEqual':
          serverFilterModel[columnID] = { endsWith: filterModel[columnID].filter };
          break;
        case 'inRange':
          serverFilterModel[columnID] = { between: [filterModel[columnID].filter, filterModel[columnID].filterTo] };
          break;
        default:
          break;
      }
    } else if (filterModel[columnID].filter?.filterType === 'DateFilter') {
      columnSchema.forEach((columnObj) => {
        let columnType;
        if (columnID === columnObj.columnName) {
          columnType = columnObj.dataType;
        }
        switch (columnType) {
          case '__d3__CreatedAtDate':
          case 'date':
          case 'datePicker':
            serverFilterModel.__d3__createdAt = { between: filterModel[columnID].filter.filter };
            break;
          case '__d3__CreatedAtTime':
            serverFilterModel.__d3__createdAt = { between: filterModel[columnID].filter.filter };
            break;
          case '__d3__UpdatedAtDate':
            serverFilterModel.__d3__updatedAt = { between: filterModel[columnID].filter.filter };
            break;
          case '__d3__UpdatedAtTime':
            serverFilterModel.__d3__updatedAt = { between: filterModel[columnID].filter.filter };
            break;

          default:
            break;
        }
      });
    }
  });
  console.log(serverFilterModel, 'serverFilterModel filtermodel');
  return serverFilterModel;
}
