function getAppID() {
  const appID = window.location.pathname.split('/')[1];
  if (appID) {
    return appID;
  }
  return null;
}

export function getAppIDFromURL() {
  return window.location.href.split('/')[3];
}

export default getAppID;

export const getDefaultAppID=(userData)=> {
  return userData?.appsConnected?.[0];
}

