import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Box } from '@mui/material';
import { LocalizationProvider, DateRangePicker as DRp } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { validate } from 'lib/utils-library';

const DateRangePicker = ({
  d3Data,
  value = ['', ''],
  onChange = () => {},
  startErr = '',
  endErr = '',
}) => {
  // const [value, setValue] = useState(value || d3Data.defaultValue || ['', '']);
  const [startError, setStartError] = useState(startErr || null);
  const [settingFirst, setSettingFirst] = useState(false);
  const [endError, setEndError] = useState(endErr || null);

  // useEffect(() => {
  //   setValue(value || d3Data.defaultValue || ['', ''])
  // }, [d3Data.defaultValue]);

  const handleChange = (newVal) => {
    // setValue(newVal);
    onChange(newVal);
    if (Object.prototype.toString.call(newVal) === '[object Array]') {
      if (!newVal[0]) {
        setEndError('Set the start date first');
        return;
      }
      if (isNaN(newVal[0].getTime())) {
        setStartError('Date is not valid');
      } else {
        setStartError(null);
      }
      if (newVal[1] && isNaN(newVal[1].getTime())) {
        setEndError('Date is not valid');
      } else {
        setEndError(null);
      }
    }
    const newError1 = validate(newVal[0], d3Data.validation);
    const newError2 = validate(newVal[1], d3Data.validation);
    if (newError1) {
      setStartError(newError1.message);
    }
    if (settingFirst && newError2) {
      setEndError(newError2.message);
    } else {
      setStartError(null);
    }
    if (newVal[0] && newVal[1] && newVal[0] > newVal[1]) {
      setStartError('Start date must be before end date');
    }
  };
  const handleClose = (newVal) => {
    setSettingFirst(true);
    if (value[0] && value[1] && Object.prototype.toString.call(value) === '[object Array]') {
      if (isNaN(value[0].getTime())) {
        setStartError('Date is not valid');
      } else {
        setStartError(null);
      }
      if (isNaN(value[1].getTime())) {
        setEndError('Date is not valid');
      } else {
        setEndError(null);
      }
    }
    const newError1 = validate(value[0], d3Data.validation);
    const newError2 = validate(value[1], d3Data.validation);
    if (newError1) {
      setStartError(newError1.message);
    }
    if (newError2) {
      setEndError(newError2.message);
      console.log(value[1]);
    }
    if (value[0] && value[1] && value[0] > value[1]) {
      setStartError('Start date must be before end date');
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DRp
        startText={d3Data.startText}
        endText={d3Data.endText}
        value={value}
        onChange={(e) => handleChange(e)}
        onClose={handleClose}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField
              {...startProps}
              helperText={startErr || startError}
              error={Boolean(startErr) !== false || startError != null}
            />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField
              {...endProps}
              helperText={endErr || endError}
              error={Boolean(endErr) !== false || endError != null}
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;

DateRangePicker.propTypes = {
  d3Data: PropTypes.shape({
    startText: PropTypes.string,
    endText: PropTypes.string,
    key: PropTypes.string,
    validation: PropTypes.array,
  }).isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  startErr: PropTypes.string,
  endErr: PropTypes.string,
};

DateRangePicker.defaultProps = {
  value: ['', ''],
  startErr: '',
  endErr: '',
};
