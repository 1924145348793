import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Box,
  Card,
  Dialog,
  Typography,
} from '@mui/material';
import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import styles from './DateFilter.module.css';
import { mls } from 'lib/multilanguagesupport';
const { DateTime } = require("luxon");

const DateFilter = (props, ref) => {
  // filter type -> today, this week, this month, this year, custom date
  const [filterType, setFilterType] = useState('all');
  const [value, setValue] = useState(['', '']);
  const [customDate, setCustomDate] = useState(false);

  // types of values
  const filterTypes = ['all', 'today', 'this week', 'this month', 'this year', 'custom date'];

  const handleChange = (e) => setFilterType(e.target.value);

  const handleClose = () => setCustomDate(false);

  useImperativeHandle(ref, () => ({
    doesFilterPass(params) {
      return true;
    },

    isFilterActive() {
      return filterType !== 'all';
    },

    getModel() {
      return filterType !== 'all' ? { filter: { filter: value, type: 'dateRange', filterType: 'DateFilter' }} : null;
    },

    setModel(model) {
      // setValue(model.value);
    },
  }));

  useEffect(() => {
    props.filterChangedCallback();
    console.log(value);
  }, [props, value]);

  useEffect(() => {
    let startDate;
    let endDate;
    const today = new Date();
    setCustomDate(false);
    endDate = today;
    switch (filterType) {
      case 'today':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
        break;
      case 'this week':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        break;
      case 'this month':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        break;
      case 'this year':
        startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        break;
      case 'custom date':
        setCustomDate(true);
        break;
      default:
        startDate = '';
        endDate = '';
        break;
    }

    setValue([startDate, endDate]);
  }, [filterType]);

  return (
    <div className={styles.mainContainer}>
      <FormControl fullWidth variant='standard'>
        <InputLabel id='date-filter-label'>{mls('Filter Date')}</InputLabel>
        <Select
          labelId='date-filter-label'
          id='date-filter'
          value={filterType}
          onChange={handleChange}
        >
          {filterTypes.map((type) => (
            <MenuItem value={type} key={type} className='ag-custom-component-popup'>
              {mls(type)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Dialog open={customDate} onClose={handleClose}>
        <Card class={styles.subContainer}>
          <Typography variant='subtitle1' color='gray' class={styles.subtitle}>
            {mls('Select Custom Date')}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDateRangePicker
              open
              displayStaticWrapperAs='desktop'
              value={value}
              onChange={(newValue) => {
                if (newValue[0] && newValue[1]) {
                  const startDate = newValue[0];
                  const endDate = DateTime.fromISO(newValue[1].toISOString()).plus({ hours: 23, minutes: 59 });
                  // console.log(newValue, startDate, endDate, 'filtermodel daterane');
                  setValue([startDate, endDate]);
                  // handleClose();
                }
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} color="primary" />
                  <Box sx={{mx: 2}}> {mls('to')} </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
        </Card>
      </Dialog>
    </div>
  );
};

export default forwardRef(DateFilter);
