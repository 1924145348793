const __APPSTORE_NAME = 'Supista_APP';
const storeData = (itemName, itemValue) => {
  try {
    localStorage.setItem(`${__APPSTORE_NAME}:${itemName}`, JSON.stringify(itemValue));
    return true;
  } catch (error) {
    return false;
  }
};

const retrieveData = (itemName) => {
  try {
    const value = localStorage.getItem(`${__APPSTORE_NAME}:${itemName}`);
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        return parsedValue;
      } catch (jsonParsingError) {
        return value;
      }
    }
  } catch (error) {
    return null;
  }
  return null;
};

export { storeData, retrieveData };
