import { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap-v5';

const CellSelect = ({
  valueFormatted,
  value,
  setValue,
  colDef: {
    cellEditorParams: { columnData, componentID, parentScreen },
    ...colDef
  },
  data,
  ...props
}) => {
  const selectMenu = columnData?.options?.selectMenu;
  const cellValue = (selectMenu || []).find((obj) => obj.value === value)?.label || value || '';

  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <>
      {cellValue?.length > 10 && (
        <Overlay target={target.current} show={show} placement='bottom'>
          {(props) => (
            <Tooltip id='overlay-example' {...props}>
              {cellValue}
            </Tooltip>
          )}
        </Overlay>
      )}

      <span ref={target} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        <span
          className='ag-cell-value'
          style={{ maxWidth: 550, width: '100%', userSelect: 'text' }}
        >
          {cellValue}
        </span>
        &nbsp;
      </span>
    </>
  );
};

export default CellSelect;
