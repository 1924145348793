function addBelongsToColumnSchema(column, relationsSchema) {
  const { relationId } = column;
  const belongsToColumns = [];
  relationsSchema.forEach((relations) => {
    if (relations.relationID === relationId) {
      const belongsToColumnMainColumn = { ...column, name: relations.screenName };
      belongsToColumns.push(belongsToColumnMainColumn);
      (relations.defaultView || []).forEach((column) => {
        if (column.columnName === 'id') belongsToColumns.push({
          columnName: `${relationId}.${column.columnName}`, dataType: "belongToTableID", name: column.name, readOnly: true, relationID: relationId,
        });
        
        else belongsToColumns.push({
          columnName: `${relationId}.${column.columnName}`, dataType: "belongToTableDisplay", name: column.name, readOnly: true, relationID: relationId,
        });
      });
    }
  });
  return belongsToColumns;
}

export default addBelongsToColumnSchema;
