const en = {
  'Target User': 'Target User',
  'Create your campaign & initial details': 'Create your campaign & initial details',
  'FAQ Page': 'FAQ Page',
  'Create Campaign': 'Create Campaign',             
  'Header': 'Header',               
  'Filters according to User': 'Filters according to User',
  'Delivery Time': 'Delivery Time',
  'Send notification at': 'Send notification at',
  'Static Time': 'Static Time', 
  'Duration': 'Duration', 
  'Starts on': 'Starts on',
  'Ends on': 'Ends on', 
  'To': 'To', 
  'weekly': 'Weekly', 
  'monthly': 'Monthly', 
  'Second Saturday': 'Second Saturday',
  'Month End': 'Month End',
  'Last Working Day': 'Last Working Day',
  'week ends on': 'week ends on',
  'Review': 'Review', 
  'Review your Notification before publishing': 'Review your Notification before publishing',
  'Installed on': 'Installed on',
  'Repetition': 'Repetition', 
  'Deliver At' : 'Deliver At',
  'Time' : 'Time',
  'Repeat' : 'Repeat',
  'Users Reached' : 'Users Reached',
  'Successful Execution' : 'Successful Execution',
  'Category': 'Category',
  'Name your campaign & initial details' : 'Name your campaign & initial details',
  'The basic device information' : 'The basic device information',
  'Creation category etc.' : 'Creation category etc.',
  'Category of user group' : 'Category of user group',
  'Filter based of Usage' :'Filter based of Usage',
  'Back' : 'Back',
  'Continue' : 'Continue',
  'Submit' : 'Submit',
  'daily': 'Daily',
  'Uncategorized': 'Uncategorized',
  'Deliver During': 'Deliver During',
  'Targetting all available users': 'Targetting all available users',

  // version 2
  'Insert the header for your campaign': 'Insert the header for your campaign',
  'Message': 'Message',
  'Tags': 'Tags',
  'Press enter to add tags': 'Press enter to add tags',
  'Notification Active': 'Notification Active',
  'is Notification Active': 'is Notification Active',
  'onlyOnce': 'Only Once',

  'M': 'M', 
  'T': 'T',
  'W': 'W',
  'Th': 'Th',
  'F': 'F',
  'S': 'S',
  'Su': 'Su',

  'On Monday': 'On Monday',
  'On Tuesday': 'On Tuesday',
  'On Wednesday': 'On Wednesday',
  'On Thursday': 'On Thursday',
  'On Friday': 'On Friday',
  'On Saturday': 'On Saturday',
  'On Sunday': 'On Sunday',
  Campaigns: 'Campaigns',

  'Are you sure to delete this campaign?': 'Are you sure to delete this campaign?',
  'Deleting Campaign': 'Deleting Campaign',

};

export default en;
