import { Card, Typography } from '@mui/material';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

export default forwardRef((props, ref) => {
  const [currentValue, setCurrentValue] = useState(props.filterParams.options.join(","));

  console.log(props.filterParams.options);
  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        console.log(parentModel);
        if (!parentModel) {
          setCurrentValue(props.filterParams.options.join(","));
        } else {
          setCurrentValue(parentModel.filter.join(","));
        }
      },
    };
  });

  return (
    <Card variant="outlined" sx={{ width: '100px', m: 'auto', p: 1, overflowX: 'scroll'}}>
      <Typography variant="info" color="gray" sx={{ fontWeight: 500 }}>{currentValue}</Typography>
    </Card>
  );
});
