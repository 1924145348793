const jp = {
  'Target User': '対象ユーザー',
  'Create your campaign & initial details': 'プッシュ通知の件名と内容を作成する',
  'FAQ Page': 'FAQページへ',
  'Create Campaign': 'プッシュ通知の作成', 
  'Header': '件名',               
  'Filters according to User': '対象ユーザーの選択',
  'Delivery Time': '送信設定',
  'Send notification at': 'プッシュ通知の送信設定を行います',
  'Static Time': '指定時間', 
  'Duration': '期間', 
  'Starts on': '開始日',
  'Ends on': '終了日', 
  'To': '～', 
  'weekly': '毎週', 
  'monthly': '毎月(複数日の選択可）', 
  'Second Saturday': '二週間後の土曜日',
  'Month End': '月末',
  'Last Working Day': '最終出勤日',
  'week ends on': '週末最終日',

  'Review': '確認', 
  'Review your Notification before publishing': '公開や送信する前にプッシュ通知を確認ください。',
  'Installed on': '設定',
  'Repetition': '繰り返し設定', 
  'Deliver At' : 'に送信',
  'Time' : '時間',
  'Repeat' : '繰り返す',
  'Users Reached' : '送信されたユーザー数',
  'Successful Execution' : '正常な実行',
  'Name your campaign & initial details' : '通知のカテゴリー・件名・内容を決める',
  'The basic device information' : '端末種類・チャネル・テスト状況・バッテリー状況',
  'Creation category etc.' : '送信日時の条件と設定',
  'Category of user group' : '繰り返し送信方法と設定',
  'Filter based of Usage' :'通知の内容と設定を確認',
  'Back' : '戻る',
  'Continue' : '次へ',
  'Submit' : '送信',
  'daily': '毎日',
  'Uncategorized': 'カテゴリー無し',
  'Deliver During': '中に送信',
  'Targetting all available users': '利用可能なすべてのユーザーを対象にする。',
  'Category': 'カテゴリー',

  // version 2
  'Insert the header for your campaign': '通知の件名を記入ください。',
  'Message': '内容',
  'Tags': 'カテゴリー２',
  'Press enter to add tags': 'Enterキーを押してカテゴリー２をタグとして追加してください。',
  'Notification Active': 'プッシュ通知利用 ON/OFF', 
  'is Notification Active': '通知内容を作成のみの場合は 「OFF」 にしてください',
  'onlyOnce': '1回だけ',

  'M': '月', 
  'T': '火',
  'W': '水',
  'Th': '木',
  'F': '金',
  'S': '土',
  'Su': '日',

  'On Monday': '月曜日',
  'On Tuesday': '火曜日',
  'On Wednesday': '水曜日',
  'On Thursday': '木曜日',
  'On Friday': '金曜日',
  'On Saturday': '土曜日',
  'On Sunday': '日曜日',
  Campaigns: 'Notifications',
  'Are you sure to delete this campaign?': '本当にこの通知を削除しますか？',
  'Deleting Campaign': '通知を削除します。',
  
}

export default jp;
