import {FC} from 'react'

const Loading: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10 mt-15'>Loading...</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        <span className='indicator-progress' style={{display: 'block'}}>
          Please wait...{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      </div>
    </>
  )
}

export default Loading;
