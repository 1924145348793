import React, {FC} from 'react'
import {DateTime} from 'luxon'
import {KTSVG} from '_metronic/helpers'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {mls} from 'lib/multilanguagesupport'
import { GMTToLocal } from 'plugins/campaigns/utility'

type Props = {
  value: any
  setValue: Function
}
const days = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su']
// prettier-ignore
const dates = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31',]
const inaButtons = 'fw-bold rounded  w-40px h-40px border-0 bg-secondary align-middle text-gray-600'
const aButtons = 'fw-bold border-0 w-40px h-40px  text-primary rounded bg-primary bg-opacity-10'
const Step5: FC<Props> = ({value, setValue}) => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>{mls('Review')}</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          {mls('Review your Notification before publishing')}.
        </div>
      </div>

      {value.campaignHeader != null && (
        <>
          <div className='d-flex flex-wrap border px-5 py-5 rounded mb-10'>
            <h3 className='fw-bolder  text-gray-500 mb-5'>{mls(value.campaignHeader)}</h3>
            {value.campaignImg != null && (
              <img
                alt='not found'
                height={'144px'}
                width={'640px'}
                className=' rounded w-100 mb-5'
                src={value.campaignImg}
              />
            )}

            <span className='fs-4 w-100 text-gray-500 mb-5'>{mls(value.campaignBody)}</span>

            {value.campaignTags.map((tags: string) => (
              <div className='d-inline-flex mb-3'>
                <span className='px-3 py-2 bg-secondary me-4 rounded-pill' key={tags}>
                  <span>{mls(tags)}</span>
                </span>
              </div>
            ))}
          </div>

          <div className='d-flex flex-wrap border px-5 py-5 rounded mb-10 '>
            <h3 className='w-100 fw-bolder text-gray-500 align-middle mt-1 mb-5'>
              {mls('Target User')}
              {
                Object.keys(value.filters || {}).length === 0
                ? `: ${mls('Targetting all available users')}`
                : ''
              }
            </h3>

            
            {
              Object.keys(value.filters || {}).map((filter) => (<div>
                {
                  value.filters[filter].length > 0
                  ? <span className='fw-bolder text-gray-500 fs-3 ms-5 '>
                      {mls(filter)}:{' '}
                      {
                        value.filters[filter].map((filterValue:string) => <div className='d-inline-flex mb-3'>
                        <span className='px-3 py-2 bg-secondary me-4 rounded'>
                          <span>{mls(filterValue)}</span>
                        </span>
                      </div>)
                      }
                    </span>
                  : ''
                }
              </div>))
            }
          </div>

          <div className='d-flex flex-wrap align-items-center border px-5 py-5 rounded mb-10 align-middle'>
            <h3 className='fw-bolder text-gray-500 align-middle mt-1'>
              {mls(value.deliveryType)}:
            </h3>
            <span className='fw-bolder text-gray-500 fs-3 ms-5 '>{GMTToLocal(value.deliverAt)}</span>
          </div>
          <div className='d-flex flex-wrap align-items-center border px-5 py-5 rounded mb-10 align-middle'>
            <h3 className='fw-bolder text-gray-500 align-middle mt-1'>
              {mls('Deliver During')}: {' '}
            </h3>
            <span className='fw-bolder text-gray-500 fs-3 ms-5 '>
              {DateTime.fromMillis(value.durationStart).toLocaleString()} {mls('To')} {DateTime.fromMillis(value.durationEnd).toLocaleString()}
            </span>
            
          </div>

          <div className='d-flex flex-wrap border px-5 py-5 rounded mb-10 '>
            <h3 className='fw-bolder text-gray-500 align-middle mt-1 mb-6'>{mls('Repetition')}: {mls(value.repetitionType)}</h3>

            {value.repetitionType === 'weekly' && (
              <div className='d-flex w-100 flex-row mb-5'>
                {days.map((day) => (
                  <span className='pe-6 '>
                    <button
                      className={value.repeatOn.includes(day) ? `${aButtons}` : `${inaButtons}`}
                      type='button'
                      disabled
                    >
                      {mls(day)}
                    </button>
                  </span>
                ))}
              </div>
            )}
            {value.repetitionType === 'monthly' && (
              <div>
                <div className='d-flex flex-wrap mb-5'>
                  {dates.map((date) => (
                    <span className='pe-6 pb-5'>
                      <button
                        className={value.repeatOn.includes(date) ? `${aButtons}` : `${inaButtons}`}
                        type='button'
                        disabled
                      >
                        {date}
                      </button>
                    </span>
                  ))}
                </div>

                {value.secondSaturday != null && (
                  <div>
                    <span className='text-gray-400 fw-bold  fs-5'>
                      <i className='bi text-primary bi-check-square-fill fs-5'></i>
                      &nbsp; &nbsp;
                      {mls('Second Saturday')}
                    </span>
                  </div>
                )}

                {value.monthEnd != null && (
                  <div>
                    <span className='text-gray-400 fw-bold fs-5'>
                      <i className='bi text-primary bi-check-square-fill fs-5'></i>
                      &nbsp; &nbsp;
                      {mls(value.monthEnd)}
                    </span>
                  </div>
                )}

                {value.lastWorkingDay != null && (
                  <div>
                    <span className='text-gray-400 fw-bold fs-5'>
                      <i className='bi text-primary bi-check-square-fill fs-5'></i>
                      &nbsp; &nbsp;
                      {mls('Last Working Day')} ({mls('week ends on')}):
                    </span>
                    <span className='text-gray-400 fw-bold fs-5'>
                      &nbsp; &nbsp;{mls(value.weekEnd)}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export {Step5}
