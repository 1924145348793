function getDefaultComponents(appData, projectID, screenGroupID, screenID) {
  const newProjectID = projectID || appData.projects?.[0]?.name;
  let projectObject = {};
  (appData.projects || []).forEach((project) => {
    if (project.name === newProjectID) {
      projectObject = project;
    }
  });

  const newScreenGroupID = screenGroupID || projectObject.screenGroup?.[0]?.name;
  let screenGroupObj = {};
  (projectObject.screenGroup || []).forEach((screenGroup) => {
    if (screenGroup.name === newScreenGroupID) {
      screenGroupObj = screenGroup;
    }
  });

  let newScreenID = screenID || screenGroupObj.screen?.[0]?.screenID;
  let newScreenName = screenGroupObj.screen?.[0]?.screenName
  let componentIDs = [];
  (screenGroupObj.screen || []).forEach((screen) => {
    if (screen.screenID === newScreenID) {
      componentIDs = screen.component;
      newScreenName = screen.screenName
    }
  });
  // if there is no component in the Screen, then home screen will be called.
  if (componentIDs?.length === 0) {
    newScreenID = null;
  }
  return { newProjectID, newScreenGroupID, newScreenID, componentIDs, newScreenName };
}

export default getDefaultComponents;
