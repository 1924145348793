import { applyRolesInTable } from 'screenComponents/getComponentSchema';
import getValidatedColumnSchema from './getValidatedColumnSchema';

function getConnectedScreenGroup(
  appSchema,
  InnerViewComponentID,
  component,
  parentTableSchema,
  teamID
) {
  const connectedScreenGroup = {};

  Object.keys(parentTableSchema?.relations || {})?.length > 0 &&
    (component?.tableList || []).forEach((relation) => {
      if (!relation?.relationID) return;
      const relationSchema = parentTableSchema.relations[relation.relationID];
      let childTableSchema = appSchema.appDatatable?.[relationSchema?.target];
      if (relationSchema && childTableSchema) {
        childTableSchema = applyRolesInTable(component, childTableSchema, teamID);

        const dataTableSchema = {
          ...childTableSchema,
          columnSchema: getValidatedColumnSchema(
            childTableSchema.columnSchema,
            relation.defaultView,
            component.tableList
          ),
          isFormCustomization:
            !!component.codeCustomization?.[childTableSchema.name]?.formValidation,
        };

        connectedScreenGroup[relation.relationID] = {
          relationSchema: {
            ...relationSchema,
            displayName: relation?.screenName || relationSchema?.displayName,
          },
          dataTableSchema,
        };
      }
      return null;
    });
  // console.log(
  //   connectedScreenGroup,
  //   component,
  //   parentTableSchema,
  //   'connectedScreenGroupwdconnectedScreenGroupwd'
  // );
  return { connectedScreenGroup };
}

export default getConnectedScreenGroup;
