import { mls } from "lib/multilanguagesupport";

const CellChips = ({ valueFormatted, value, setValue, colDef: { cellEditorParams: { columnData } }, ...props }) => {
  const cellValue = valueFormatted ? valueFormatted : value;
  // const buttonClicked = () => {
  //     alert(`${cellValue} medals won!`);
  //     setValue(cellValue + 1);
  // }
  let returnObj = '';
  if (cellValue === true) {
    returnObj = (
      <span>
        <span className='badge badge-light-success fs-8 fw-bolder'>{columnData.trueValue || mls('Yes')}</span>
      </span>
    );
  } else if (cellValue === false) {
    returnObj = (
      <span>
        <span className='badge badge-light-danger fs-8 fw-bolder'>{columnData.falseValue || mls('No')}</span>
      </span>
    );
  }

  
  return returnObj;
}

export default CellChips;
