import { v4 as uuid } from 'uuid';
import { _fetchDataToPlot, _uploadFileToServer } from 'lib/server-connection/connections';
import { useDispatch } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';

function useSupistaDatabase() {
  const dispatch = useDispatch();

  return async (appID) => {
    const apiEndPoint = `${appID}/api/createCredentials/credentials`;
    const credentialID = uuid();
    const credData = {
      appID: appID,
      credentialID: credentialID,
      connectionName: 'Supista',
      description: 'This connections contains tables present in the Supista App.',
      refreshInterval: '3',
      connectorType: '__d3__supista',
      data: {},
    };
    const reqFormData = new FormData();
    reqFormData.append('connectorType', credData.connectorType);
    reqFormData.append('appID', credData.appID);
    reqFormData.append('credentialID', credData.credentialID);
    reqFormData.append('data', JSON.stringify(credData.data));
    reqFormData.append('description', credData.description);
    reqFormData.append('refreshInterval', credData.refreshInterval);
    reqFormData.append('connectionName', credData.connectionName);

    await _uploadFileToServer(apiEndPoint, reqFormData);
    const res = await _fetchDataToPlot({ appID }, `${appID}/allSchema`);
    if (res.__d3__error !== true) {
        dispatch(
        create({
            setPath: reduxConstants.config.ANALYTICS_SCHEMA,
            value: res.response,
        })
        );
    }

  };
}

export default useSupistaDatabase;
