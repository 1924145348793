import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { supistaApiPost } from './network/connections';
import { passwordSchema } from './schema/Schema';

const initialValues = {
  setPassword: '',
  confirmPassword: '',
};

export function RegisterNewUser() {
  const history = useHistory();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const data = {
        data: {
          newPassword: formik.values.setPassword,
          confirmNewPassword: formik.values.confirmPassword,
          secretLoginKey: params.secretLoginKey,
        },
      };
      supistaApiPost(`auth/${params.appID}/onboardUser`, data)
        .then((response) => {
          // console.log(response);
          setLoading(false);
          if (response.hasOwnProperty('__d3__success')) history.push('/auth/login');
          else {
            setSubmitting(false);
            setStatus(response.message);
          }
        })
        .catch(() => {
          setLoading(false);
          setSubmitting(false);
          setStatus('Sorry, looks like there are some errors detected, please try again.');
        });
    },
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{mls('Set new password')}</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{mls(formik.status)}</div>
        </div>
      ) : (
        <>{''}</>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{mls('Password')}</label>
        <input
          placeholder='password'
          {...formik.getFieldProps('setPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.setPassword && formik.errors.setPassword },
            {
              'is-valid': formik.touched.setPassword && !formik.errors.setPassword,
            }
          )}
          type='password'
          name='setPassword'
          autoComplete='off'
        />
        {formik.touched.setPassword && formik.errors.setPassword && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger' role='alert'>
              {mls(formik.errors.setPassword)}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {mls('Confirm password')}
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          placeholder='confirm password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='text-danger' role='alert'>
                {mls(formik.errors.confirmPassword)}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{mls('Continue')}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {mls('Please wait...')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
