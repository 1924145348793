const reduxConstants = {
  STORE_NAME: 'config_store',
  actions: {
    CREATE: 'create',
    UPDATE: 'update',
  },
  config: {
    LOGIN: 'login',
    APP_STATE: 'appState',
    TABLE_SCHEMA: 'table-schema',
    DASH_TABLE_SCHEMA: 'dash-table-schema',
    CHART_SETTINGS: 'chartSettings',
    DASH_SETTINGS: 'dashSettings',
    REPORT_SETTINGS: 'reportSettings',
    KPI_SETTINGS: 'kpiSettings',
    READY_FOR_QUERY: 'readyForQuery',
    APP_SCHEMA: 'appSchema',
    KPI_ALL_SCHEMA: 'kpiAllSchema',
    ANALYTICS_SCHEMA: 'analyticsSchema',
    USER_SCHEMA: 'userSchema',
    APP_MLS: 'appMLS',
    DATA_TABLE: 'appDatatable',
    APP_STATUS: {
      TOAST: 'appStatus.toast',
      ERROR_TOAST: 'appStatus.errorToast',
      LOADING: 'appStatus.loading',
    },
    ALL_TABLES: 'allTables',
    JOIN_TABLES: 'appJointable',
    CUSTOM_TABLES: 'appCustomtable',
    FILTERS: 'filters',
    DASH_FILTERS: 'dash_filters',
    DATE_TIME_TOGGLE: 'dateTimeToggle',
  },
};

export default reduxConstants;
