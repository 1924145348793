import * as Yup from 'yup'

export interface ICreateAccount {
  staticTime: string

  averageOpeningTime: string
  usersMostActiveTime: string
}

const createAccountSchemas = [
  Yup.object({
    staticTime: Yup.string().label('Static Time:'),
  }),
  Yup.object({}),
]

const inits: ICreateAccount = {
  staticTime: '0',
  averageOpeningTime: '',
  usersMostActiveTime: '',
}

export {createAccountSchemas, inits}
