import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { validate } from 'lib/utils-library';
import 'bootstrap/dist/css/bootstrap.min.css';
import useOperations from 'lib/reusable-components/hooks/fetchERPdata/useOperations';
import { Menu, MenuItem, Typography } from '@mui/material';

const BelongsTo = ({
  component,
  d3Data,
  value = '',
  onChange = () => {},
  onBlur = () => {},
  err = '',
}) => {
  const operations = useOperations(component, null, false, {
    as: d3Data.relationId,
    type: 'belongsTo',
  });
  const items = operations?.data?.__d3__error ? [] : operations?.data?.rows || [];

  // const [value, setValue] = useState(value || d3Data.defaultValue || '');
  const [selectedItem, setSelectedItem] = useState(
    (items || []).filter((it) => it.id === value)?.[0] || {}
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState(err || null);
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const belongsToSchema = (component.tableList || []).filter(
    (obj) => obj.relationID === d3Data.relationId
  );
  const displayItems = (belongsToSchema?.[0]?.defaultView || []).map((obj) => obj.columnName);

  useEffect(() => {
    setSelectedItem((items || []).filter((it) => it.id === value)?.[0] || {});
    // setValue(value || d3Data.defaultValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // useEffect(() => {
  //   // setValue(value || d3Data.defaultValue || '');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [d3Data.defaultValue]);

  console.log(selectedItem, value, d3Data, 'selectedItemselectedItem');

  // const handleChange = (val) => {
  //   console.log(val, 'valval');
  //   setValue(val);
  //   onChange(val);
  //   const newError = validate(val, d3Data.validation);
  //   if (newError) {
  //     setError(newError.message);
  //   } else {
  //     setError(null);
  //   }
  // };

  // const handleBlur = () => {
  //   onBlur(currValue);
  //   const newError = validate(currValue, d3Data.validation);
  //   if (newError) {
  //     setError(newError.message);
  //   }
  //   setEditing(false);
  // };

  const handleTextClick = (event) => {
    setEditing(true);
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  // const renderInput = (props) => (
  //   <input
  //     {...props}
  //     className={`form-control ${err || error ? 'is-invalid' : ''}`}
  //     onBlur={handleBlur}
  //   />
  // );

  // const renderItem = (item, isHighlighted) => (
  //   <div
  //     key={item.id}
  //     className={`item ${isHighlighted ? 'bg-primary text-white' : ''}`}
  //     style={{ padding: '8px' }}
  //   >
  //     {`${item[displayItems[0]]} - ${item[displayItems[1]]}`}
  //   </div>
  // );

  const handleOpen = (event) => {
    setEditing(true);
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (item) => {
    if (item) {
      setSelectedItem(item);
      onChange(item.id);
      // setValue(item.id);
    }
    const newError = validate(value, d3Data.validation);
    if (newError) {
      setError(newError.message);
    }

    setAnchorEl(null);
  };

  return (
    <div className='row'>
      <label className='col-sm-4 col-lg-3 col-form-label'>{d3Data.name}</label>
      <div
        id='pppp'
        className='col-sm-8 col-lg-9 d-flex align-items-center'
        style={{ height: '55px' }}
      >
        {value ? (
          <Typography
            onClick={handleOpen}
            variant='body1'
            sx={{ cursor: 'pointer', display: 'inline-block' }}
          >
            {(displayItems || []).map((it) => (
              <>{`${selectedItem[it] || ''}, `}</>
            ))}
          </Typography>
        ) : (
          <span
            className='form-control-plaintext'
            style={{
              textDecoration: 'underline dotted',
              cursor: 'pointer',
              color: value ? 'inherit' : '#009ef7',
            }}
            onClick={handleTextClick}
          >
            {'Click to edit'}
          </span>
        )}
        {isEditing && (
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {(items || []).map((item) => (
              <MenuItem key={item.id} onClick={() => handleChange(item)}>
                {item.TA_name}
              </MenuItem>
            ))}
          </Menu>
          // <Autocomplete
          //   getItemValue={(item) => item}
          //   items={items}
          //   renderInput={renderInput}
          //   renderItem={renderItem}
          //   value={currValue}
          //   onChange={(e) => handleChange(e.target.value)}
          //   onSelect={(val) => handleChange(val)}
          //   inputProps={{ ref: inputRef }}
          //   wrapperStyle={{ position: 'relative' }}
          //   menuStyle={{
          //     position: 'absolute',
          //     top: '100%',
          //     left: 0,
          //     right: 0,
          //     zIndex: 1000,
          //     background: '#fff',
          //     border: '1px solid #ccc',
          //     borderRadius: '4px',
          //     maxHeight: '200px',
          //     overflowY: 'auto',
          //   }}
          //   disableClearable
          //   disableCloseOnSelect
          // />
        )}
        {(err || error) && <div className='invalid-feedback'>{err || error}</div>}
      </div>
    </div>
  );
};

export default BelongsTo;

BelongsTo.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.string,
    validation: PropTypes.array,
  }).isRequired,
  value: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

BelongsTo.defaultProps = {
  value: '',
  onBlur: () => null,
  err: '',
};
