/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import { mls } from 'lib/multilanguagesupport';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CSVUpload from 'screenComponents/csvUpload';

type Props = {
  downloadCSV: Function,
  uploadCSV: Function,
  tableSchema: any,
}

const TableExtraOptions: FC<Props> = ({ downloadCSV, uploadCSV, tableSchema }) => {
  const [isOpen, setOpen] = useState(false);
  const [isUploadOpen, setUploadOpen] = useState(false);
  return (
    <>
      {
        isUploadOpen ? <CSVUpload tableSchema={tableSchema} uploadCSV={uploadCSV} closeModal={setUploadOpen} /> : ''
      }
      <Dropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
        <DropdownToggle
          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
          // onClick={() => setOpen(!isOpen)}
        >
          <i className='bi bi-three-dots fs-3'></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem className='px-5 py-3' header>{mls('Table Options')}</DropdownItem>
          <DropdownItem divider />
          <DropdownItem className='px-5 py-3' onClick={() => setUploadOpen(true)}>
              <i
                className='fas fa-upload ms-2 fs-7 px-2'
                data-bs-toggle='tooltip'
                title={mls('Import your existing CSV data to this table.')}
              />
              {mls('CSV Import')}</DropdownItem>
          <DropdownItem className='px-5 py-3' onClick={() => downloadCSV()}>
              <i
                className='fas fa-download ms-2 fs-7 px-2'
                data-bs-toggle='tooltip'
                title={mls('Export the filtered CSV data of this table.')}
              />
              {mls('CSV Export')}
            </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default TableExtraOptions;
