import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {Form} from 'react-bootstrap-v5'
import moment from 'moment'
import {useState, useEffect} from 'react'
import {number} from 'yup/lib/locale'
import {mls} from 'lib/multilanguagesupport'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { GMTToLocal, localToGMT } from 'plugins/campaigns/utility'
const { DateTime } = require("luxon");

type Props = {
  value: any
  setValue: Function
}
let hour = '08'
let minutes = '00'
const Step3: FC<Props> = ({value, setValue}) => {
  const [staticTime, updateStaticTime] = useState(() => {
    let time = value.deliverAt;
    console.log(value.deliveryType, 'val, val, delieveryt');
     
    if (time) {
      time = GMTToLocal(time);
      hour = time.split(':')[0]
      minutes = time.split(':')[1]
    }
    return (time || '08:00');
  })
  const [durationStart, updateDurationStart] = useState<number>(value.durationStart || 0)
  const [durationEnd, updateDurationEnd] = useState<number>(value.durationEnd || 0)
  const hours = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
  const mins = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']
  useEffect(() => {
    setValue({
      ...value,
      deliverAt: localToGMT(staticTime),
      durationStart: durationStart,
      durationEnd: durationEnd,
    })
    console.log(value)
  }, [staticTime, durationStart, durationEnd])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>{mls('Delivery Time')}</h2>

        <div className='text-gray-400 fw-bold fs-6'>{mls('Send notification at')}:</div>
      </div>
      <div className='d-flex flex-wrap'>
        <div className='form-check form-check-custom form-check-solid me-16 mb-6 mt-3'>
          <span className='form-check form-check-custom form-check-solid'>
            <Field
              className='form-check-input h-15px w-15px'
              type='radio'
              name='deliveryType'
              value='Static Time'
              onClick={(e: any) => setValue({...value, deliveryType: e.target.value})}
              checked={value.deliveryType === 'Static Time'}
            />
          </span>
          <label className='form-check-label text-gray-500 fw-bold fs-5'>
            {mls('Static Time')}:
          </label>
        </div>
        <Field
          as='select'
          name='staticTimeHour'
          className='form-select form-select-md form-select-solid fw-bold text-gray-400 ms-20 w-75px h-40px '
          value={hour}
          onChange={(e: any) => {
            hour = e.target.value
            updateStaticTime(`${hour}:${minutes}`)
          }}
        >
          {hours.map((hour) => (
            <option>{hour}</option>
          ))}
        </Field>
        <span className='text-dark fw-bolder fs-5 mt-3 px-5'>:</span>
        <Field
          as='select'
          name='staticTimeMin'
          className='form-select form-select-md form-select-solid fw-bold text-gray-400 w-75px h-40px '
          onChange={(e: any) => {
            minutes = e.target.value
            updateStaticTime(`${hour}:${minutes}`)
            console.log(staticTime, 'staticTime')
          }}
          defaultValue='00'
          value={minutes}
        >
          {mins.map((min) => (
            <option>{min}</option>
          ))}
        </Field>
      </div>
      {/* <div className='d-flex flex-wrap'>
        <div className='form-check form-check-custom form-check-solid me-10 mb-10'>
          <span className='form-check form-check-custom form-check-solid'>
            <Field
              className='form-check-input h-15px w-15px'
              type='radio'
              name='repetition'
              value='Average Opening Time'
              onClick={(e: any) => setValue({...value, deliveryType: e.target.value})}
            />
          </span>
          <label className='form-check-label text-gray-500 fw-bold fs-5 '>
            Average opening time:
          </label>
        </div>
        <Field
          as='select'
          name='averageOpeningTime'
          className='form-select form-select-md form-select-solid fw-bold text-gray-400 col-md mb-9'
          onBlur={(e: any) => setValue({...value, deliverAt: e.target.value})}
        >
          <option>Instantaneous</option>
          <option>After 1 Minute</option>
          <option>After 5 Minutes</option>
          <option>After 10 Minutes</option>
          <option>After 20 Minutes</option>
          <option>After 25 Minutes</option>
        </Field>
      </div>
      <div className='form-check form-check-custom form-check-solid me-10 mb-10'>
        <span className='form-check form-check-custom form-check-solid'>
          <Field
            className='form-check-input h-15px w-15px'
            type='radio'
            name='repetition'
            value='User’s Most Active time'
            onClick={(e: any) => setValue({...value, deliveryType: e.target.value})}
          />
        </span>
        <label className='form-check-label text-gray-500 fw-bold fs-5'>
          User’s Most Active time
        </label>
      </div> */}

      <div className=''>
        <h2 className='fw-bolder text-dark'>{mls('Duration')}</h2>
        <div className='d-flex flex-wrap pb-2'>
          <span className='col-4 px-10 text-gray-400 fs-5 fw-bolder'>{mls('Starts on')}</span>
          <span className='col-6 text-gray-400 ms-3 fs-5 fw-bolder'>{mls('Ends on')}</span>
        </div>
        <div className='d-flex flex-wrap'>
          <span>
            <Form.Control
              style={{backgroundColor: '#F5F8FA'}}
              onChange={(e: any) => {
                console.log(e.target.value, 'console.log(e.target.value);');
                const date = new Date(e.target.value);
                updateDurationStart(date.getTime())
              }}
              className=' text-gray-500 fw-bolder'
              size='sm'
              type='date'
              name='start'
              value={DateTime.fromMillis(durationStart).toISODate()}
            />
          </span>
          <span className=' text-center text-gray-800 fw-bolder fs-5 py-3 px-5'>{mls('To')}</span>
          <span>
            <Form.Control
              style={{backgroundColor: '#F5F8FA'}}
              className='  text-gray-500 fw-bolder'
              size='sm'
              type='date'
              name='end'
              value={DateTime.fromMillis(durationEnd).toISODate()}
              onChange={(e: any) => {
                console.log(e.target.value, 'console.log(e.target.value);');
                const date = new Date(e.target.value);
                updateDurationEnd(date.getTime())
              }}
            />
          </span>
        </div>
      </div>
      <div className="mt-10">
        <div className='pb-5 pb-lg-8'>
          <h2 className='fw-bolder text-dark'>{mls('Notification Active')}</h2>
        </div>
          <div className='d-flex flex-wrap'>
            <FormGroup>
              <FormControlLabel control={
                <Switch
                  defaultChecked={value.status === 'Active'}
                  onClick={(e: any) => {
                  e.target.checked
                    ? setValue({...value, status: 'Active',})
                    : setValue({...value, status: 'Inactive',})
                  }}
                />
                } label={mls('is Notification Active')} />
            </FormGroup>
          </div>
      </div>
    </div>
  )
}

export {Step3}
