function localToGMT(time) {
  const hour = parseInt(time.split(':')[0]);
  const totalMinutes = hour*60 + parseInt(time.split(':')[1]);
  const date = new Date();
  const timeOffset = date.getTimezoneOffset();
  const offsetMinutes = totalMinutes + timeOffset;
  let GMTMinute = offsetMinutes % 60;
  let GMTHour = Math.floor(offsetMinutes / 60) % 24;
  if (GMTHour < 0) {
    GMTHour = 24 + GMTHour;
  }
  if (GMTMinute < 0) {
    GMTMinute = 60 + GMTMinute;
  }
  return `${String(GMTHour).padStart(2, '0')}:${String(GMTMinute).padStart(2, '0')}`;
}

function GMTToLocal(time) {
  const hour = parseInt(time.split(':')[0]);
  const totalMinutes = hour * 60 + parseInt(time.split(':')[1]);
  const date = new Date();
  const timeOffset = date.getTimezoneOffset();
  const offsetMinutes = totalMinutes - timeOffset;
  let GMTMinute = offsetMinutes % 60;
  let GMTHour = Math.floor(offsetMinutes / 60) % 24;
  if (GMTHour < 0) {
    GMTHour = 24 + GMTHour;
  }
  if (GMTMinute < 0) {
    GMTMinute = 60 + GMTMinute;
  }
  return `${String(GMTHour).padStart(2, '0')}:${String(GMTMinute).padStart(2, '0')}`;
}

export { localToGMT, GMTToLocal };
