import React, {FC} from 'react'
import {useState, useEffect} from 'react'
import {KTSVG} from '_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {Form} from 'react-bootstrap-v5'
import moment from 'moment'
import {mls} from 'lib/multilanguagesupport'
import MultiSelectDropdown from 'plugins/campaigns/createCampaign/multiSelectDropdown'
type FieldProps = {
  fieldSchema: {
    name: string
    columnName: string
    dataType: string
    selectMenu: [
      {
        label: string
        value: string
      }
    ]
  }
  setNewValue: Function
  fetchedItems?: any
  value?: any
}

const DateField: FC<FieldProps> = ({fieldSchema, setNewValue}) => {
  const [dateRange, setDateRange] = useState<{from: number | null; to: number | null}>({
    from: null,
    to: null,
  })
  const handleDateRange = (newDateRange: {from: number | null; to: number | null}) => {
    setDateRange(newDateRange)
    setNewValue({[fieldSchema.columnName]: newDateRange})
  }

  return (
    <div className='row mb-5'>
      <label className='col-4 fw-bolder text-gray-500 fs-5 align-middle pt-3'>Installed On:</label>
      <div className='col-8'>
        <div className='d-flex flex-wrap'>
          <Form.Control
            style={{backgroundColor: '#F5F8FA'}}
            onChange={(e: any) =>
              handleDateRange({
                ...dateRange,
                from: moment(`${e.target.value}`, 'YYYY.MM.DD').unix(),
              })
            }
            className=' col-md text-gray-500 fw-bolder'
            type='date'
            name='start'
          />
          <span className='col-sm text-center text-gray-800 fw-bolder fs-5 py-3'>To</span>
          <Form.Control
            style={{backgroundColor: '#F5F8FA'}}
            onChange={(e: any) =>
              handleDateRange({...dateRange, to: moment(`${e.target.value}`, 'YYYY.MM.DD').unix()})
            }
            className=' col-md text-gray-500 fw-bolder'
            type='date'
            name='end'
          />
        </div>
      </div>
    </div>
  )
}

const SelectField: FC<FieldProps> = ({fieldSchema, setNewValue, fetchedItems, value}) => {
  return (
    <div className='row mb-5'>
      <label className='col-4 fw-bolder text-gray-500 fs-5 align-middle pt-3'>
        {mls(fieldSchema.name)}:
      </label>
      <div className='col-8'>
        <MultiSelectDropdown
          fieldSchema={fieldSchema}
          setNewValue={setNewValue}
          fetchedItems={fetchedItems}
          value={value}
        />
        {/* <Field
          as='select'
          name='brand'
          className='form-select form-select-md form-select-solid fw-bold text-gray-400'
          onChange={(e: any) => setNewValue({[fieldSchema.columnName]: e.target.value})}
        >
          {fetchedItems
            ? (fetchedItems || []).map((menu: {distinctVal: any}) => (
                <option value={menu.distinctVal}>{mls(menu.distinctVal)}</option>
              ))
            : (fieldSchema.selectMenu || []).map((menu) => (
                <option value={menu.value}>{mls(menu.label)}</option>
              ))}
        </Field> */}
      </div>
    </div>
  )
}

type Props = {
  value: any
  setValue: Function
  getOptions: Function
  schema: any
}

const Step2: FC<Props> = ({value, setValue, schema, getOptions}) => {
  const [fetchedItems, setFetchedItems] = useState<any>({})

  useEffect(() => {
    const responses = ((schema.columnsToFilter as Array<FieldProps['fieldSchema']>) || []).map(
      async (fieldSchema) => {
        if (fieldSchema.dataType === 'multipleSelect-foreignFetch') {
          const response = await getOptions(fieldSchema.columnName)
          return {[fieldSchema.columnName]: response}
        }
      }
    )
    let allFetchedItems = {}
    Promise.all(responses).then((values) => {
      values.forEach((value) => {
        // console.log(allFetchedItems, 'columnName');
        allFetchedItems = {...allFetchedItems, ...value}
        setFetchedItems(allFetchedItems)
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log(schema, 'schema.columnsToFilter')
  const setNewValue = (newVal: any) => {
    setValue({...value, filters: { ...value.filters || {}, ...newVal}})
  }
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>{mls('Target User')}</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          {mls('Filters according to User')}
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            {mls('FAQ Page')}
          </a>
          .
        </div>
      </div>

      <div className=' '>
        {((schema.columnsToFilter as Array<FieldProps['fieldSchema']>) || []).map((fieldSchema) => {
          switch (fieldSchema.dataType) {
            case 'select':
              console.log(fieldSchema, 'Field')
              return <SelectField fieldSchema={fieldSchema} setNewValue={setNewValue} value={value.filters?.[fieldSchema.columnName]} />
            case 'multipleSelect-foreignFetch':
              return (
                <SelectField
                  fieldSchema={fieldSchema}
                  setNewValue={setNewValue}
                  fetchedItems={fetchedItems[fieldSchema.columnName]}
                  value={value.filters?.[fieldSchema.columnName]}
                />
              )
            case 'dateRange':
              return <DateField fieldSchema={fieldSchema} setNewValue={setNewValue} />
            default:
              return ''
          }
        })}
      </div>
    </div>
  )
}

export {Step2}
