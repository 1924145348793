const jp = {
  uploadCSV: 'CSVアップロード',
  CSVFileUploadedSuccessfully: 'CSVファイルが正常にアップロードされました!',
  dragndropCSV: 'CSVファイルをドラッグアンドドロップしてここにコンテンツをアップロードしますか！クリックしてファイルを選択することもできます',
  dropFilesHere: 'ここにファイルをドロップしてください。。。',
  clickToUpload: '[ファイルアップロード]をクリックしてください',
  CheckBoxOptions: 'チェックボックスの条件',
  Yes: 'はい',
  No: 'いいえ',
  dataUploadedSuccessfully: 'データが正常に更新されました',
  dataCreatedCuccessfully: 'データが正常に作成されました',
  addModule: '新しいモジュールとサブモジュールを追加する',
  mainModule: '必ず一意のモジュール名を入力してください',
  subModule: '必ず一意のサブモジュール名を入力してください',
  navModule: 'メニューバーのモジュールとサブモジュール',
  selectModule: 'オプションからモジュールを選択します',
  multiFormModule: 'すべてのモジュールとサブモジュール',
  dragModule: 'このモジュールをドラッグして、その位置を再配置します',
  dragSubModule: 'このサブモジュールをドラッグして、その位置を再配置します',
  deleteModule: 'モジュールとそれに対応するサブモジュールを削除します',
  deleteSubModule: 'このサブモジュールを削除します',
  dataVisualize: '下のデータをクリックして入力し、データビジュアライザー（グラフ）を入手してください',
  visualizeName: 'データビジュアライザー（グラフ）の名前を入力してください',
  visualizeType: 'グラフやチャートのタイプを選択します',
  visualizeOpType: 'グラフやチャート出力、表示のタイプを選択します',
  visualizeUniqueField: 'テキストボックス名を入力してください',
  visualizeXAxis: 'X-軸のパラメータを選択します',
  visualizeCategories: 'データビジュアライザー（グラフ）のカテゴリーを作成します。複数持つこともできます',
  visualizeLabel: 'カテゴリー名（ラベル）を入力してください',
  visualizeColor: 'カテゴリーに適した色を選択してください',
  visualizeValue: 'カテゴリーの値を入力してください',
  visualizeFilter: 'データビジュアライザー（グラフ）のフィルターはオプションです',
  visualizeDateRange: '日付タイプデータを入力します',
  visualizeDateTimeGroup: 'オプションから時間のデフォルト値を入力します',
  Remove: '削除',
  close: '閉じる',
  Module: 'モジュール',
  AddNewModule: '新しいモジュールを追加',
  SubModule: 'サブモジュール',
  AddFieldsinyourLayout: '画面レイアウトにテキストボックスを追加する',
  AddField: 'テキストボックスを追加する',
  AddNewFields: '新しいテキストボックスを追加する',
  Save: '保存',
  DataVisualization: 'データビジュアライザー（グラフ）',
  EditNewGraph: '新しいグラフを編集する',
  AddNewVisualizer: '新しいデータビジュアライザー（グラフ）を追加する',
  CategoryAdded: 'カテゴリーが追加されました',
  CannotAddCategory: 'カテゴリの追加はできません。すべての値を入力してください',
  CategoryRemoved: 'カテゴリーが削除されました',
  SelectAllFilters: 'すべてのフィルターを選択',
  Filters: 'フィルター',
  Categories: 'カテゴリー',
  SelectAllCategories: 'すべてのカテゴリーを選択',
  AddNewCategory: '新しいカテゴリを追加',
  XAxisType: 'X-軸タイプ',
  DateRange: '日付範囲',
  DateTimeGroup: '日時グループ',
  UniqueField: 'ユニークフィールド',
  Label: 'ラベル',
  Color: '色',
  SumUpon: '合計',
  AverageUpon: '平均',
  Operand1toMultiply: '乗算するオペランド1',
  Operand2toMultiply: '乗算するオペランド2',
  BarChart: '棒グラフ',
  Donut: 'Donutグラフ',
  Pie: '円グラフ',
  Line: '線グラフ',
  Area: '面グラフ',
  Count: '勘定・計数',
  CountUnique: 'ユニークな勘定・計数',
  Sum: '合計',
  SumofProduct: '商品の合計',
  Category: 'カテゴリー',
  Average: '平均',
  TimeSeries: '時系列',
  Number: '数字',
  Date: '日付',
  Day: '日',
  Year: '年',
  Month: '月',
  Quarter: '四半期',
  Week: '週間',
  Hour: '時間',
  LastModified: '最終更新日',
  CreatedAt: '作成日',
  DisplayTextwhenValueisTrue: '値が O（True）の場合テキストを表示する',
  DisplayTextwhenValueisFalse: '値が X（False）の場合テキストを表示する',
  Key: 'キー',
  Text: 'テキスト',
  Password: 'パスワード',
  Select: '選択',
  MultipleSelect: '複数選択',
  AutoComplete: 'オートコンプリート',
  Time: '時間',
  DatewithTime: '日付と時間',
  CheckBox: 'チェックボックス',
  Switch: '切り替え',
  File: 'ファイル',
  Image: 'イメージ',
  Type: 'タイプ',
  FieldName: 'フィールド（テキストボックス）名',
  SetRoles: '役割を設定する',
  StakeHolders: '利害関係者',
  Create: '作成',
  Read: '読む',
  Update: '更新',
  IsDetailedOnly: '詳細のみ',
  IsDisabled: '無効になってます',
  ReadOnly: '読み取り専用',
  AddNewOption: '新しいオプションを追加',
  DynamicOptions: '動的オプション',
  Options: 'オプション',
  Value: '値（情報）',
  IsRequired: '必要項目です',
  IsLength: '長いです',
  IsEmail: 'メールアドレスです',
  IsNumeric: '数値です',
  IsAlphabet: '文字（アルファベット）です',
  IsAlphabetorIsNumeric: '文字（アルファベット）または数値です',
  IsBool: 'ブール値です',
  IsCreditCard: 'クレジットカードです',
  IsCurrency: '通貨です',
  IsDate: '日付です',
  IsUppercase: '大文字です',
  IsLowercase: '小文字です',
  IsMobile: 'モバイルです',
  IsUrl: 'URLです',
  ErrorMessage: 'エラーメッセージ',
  MinimumLength: '最小の長さ',
  MaximumLength: '最大長',
  Error: 'エラー',
  MouduleSubModuleCannotBeSameAsParent: 'モジュール名とサブモジュール名は親項目と同じにすることはできません!',
  ThisFieldisRequired: 'この項目は必須です！',
  ModuleStructure: 'モジュール構造',
  SubModuleaddedSuccessfully: 'サブモジュールが正常に追加されました',
  ThisModuleandSubModulealreadyexist: 'このモジュールとサブモジュールはすでに存在します!',
  ModuleandSubModuleaddedSuccessfully: 'モジュールとサブモジュールが正常に追加されました',
  BothModuleandSubModulevaluesarerequired: 'モジュールとサブモジュールの両方の値が必要です',
  DefaultLanguage: '既定の言語:',
  AutoTranslateRemainingTexts: '残りのテキストを自動翻訳する',
  AddLanguage: '言語を追加する',
  SubModules: 'サブモジュール',
  AddNewSubModule: '新しいサブモジュールを追加する',
  ModuleSettings: 'モジュール設定',
  EditNewModule: '新しいモジュールの編集',
  SubModuleName: 'サブモジュール名',
  IconLink: 'アイコンURL',
  ShowinNavbar: 'メニューバーに表示する',
  Roles: 'ロール権限',
  AccessRoles: 'アクセス権限',
  Stakeholders: '関係者',
  Access: 'アクセス',
  AppSettings: 'App設定',
  FormLayout: '画面レイアウト',
  WorkFlow: 'ワークフロー',
  Settings: '設定',
  DataVisualisation: 'グラフ表示',
  NewDesignation: '新権限指定',
  EditDesignationPressEnter: '権限指定を編集してEnterキーを押します',
  Designation: '権限指定',
  UserDesignations: 'ユーザー権限',
  ReloadToolTip: 'リロード',
  Filter: 'フィルター',
  ResetFilter: 'フィルタをリセット',
  Expand: '拡大',
  Daily: '毎日',
  Weekly: '週別',
  Monthly: '月別',
  Quarterly: '四半期',
  Yearly: '年間',
  CreateWorkflow: 'ワークフローの作成',
  SelectActionType: 'アクションタイプの選択',
  GeneratePDF: 'PDFを作成する',
  Mail: 'E-メール',
  SMS: 'SMS',
  AddNewAction: '新しいアクションの追加',
  PDFName: 'PDF名',
  DownloadFileName: 'ファイル名をダウンロードする',
  FormName: '画面名',
  SaveallForms: 'すべての画面を保存',
  Name: '名前',
  GoToStatus: 'ステータスに移動',
  ButtonStyle: 'ボタンスタイル',
  Contained: '普通のボタン',
  Outlined: 'アウトラインボタン',
  HideAdvanced: '高度なオプションの非表示',
  ShowAdvanced: '高度なオプションの表示',
  SetButtonAccess: 'ボタンアクセスの設定',
  AccessTo: 'アクセスへ',
  ButtonSettings: 'ボタン設定',
  NewButton: '新しいボタン',
  AddNewButton: '新しいボタンを追加',
  RoleAccess: 'ロール権限',
  Delete: '削除',
  StateSettings: 'ワークフロー状態設定',
  StateName: 'ワークフロー状態名',
  AddNew: '新しい追加する',
  ColumnName: '列名',
  ColumnValue: '列の値',
  EntertheStateName: '状態名を入力してください',
  EditSettings: '編集の設定',
  EditWorkflowSettings: 'ワークフロー設定の編集',
  ParentForm: '親画面',
  ReferenceVariable: '参照変数',
  ReferenceName: '参照名',
  HeaderColumn: 'ヘッダー列',
  HeaderValues: 'ヘッダー値',
  Style: 'スタイル',
  VerticalTabs: '垂直タブ',
  SinglePage: '単一ページ',
  SelectFormfromanyModule: '任意のモジュールから画面(フォーム)を選択します',
  LayoutType: 'レイアウト区分',
  SamePageCRUD: '同じページのCRUD',
  FormOnly: '画面のみ',
  MultiColumnForm: 'マルチ列画面',
  ModuleHere: 'モジュール: ',
  SubModuleHere: 'サブモジュール: ',
  ViewAccesstousers: 'ユーザーへのアクセスを表示:',
  Email: 'E-メール',
  ConfirmPassword: 'パスワードを認証する',
  UserID: 'ユーザーID',
  Role: 'ロール権限',
  IsActive: 'アクティブです',
  ModuleLayoutType: 'モジュールレイアウト区分',
  None: '無し',
  MultiForm: 'マルチ画面',
  OtherSettings: 'その他設定',
  ModuleLayout: 'モジュールレイアウト',
  Database: 'データベース',
  LoginForm: 'ログイン画面',
  ReadSettings: '設定を読む',
  Show: '設定の表示',
  InputType: '入力方式',
  AddNewCondition: '新しい条件を追加',
  DownloadCSV: 'CSVダウンロード',
  NewForm: '新しい画面',
  AddNewForm: '新しい画面の追加',
  Constant: '定数',
  UserInput: 'ユーザー入力',
  Session: 'セッション',
  Thisfieldisrequired: 'この項目は必須です',
  NoBack: '戻ることはありません',
  GeneralSettings: '一般設定',
  MultiLanguageSupport: '多言語サポート',
  Thisfieldisarequiredfield: 'この項目は必須です',
  Dateisnotafterspecifieddate: '日付が指定日以降ではないです',
  Containscharactersotherthanalphabet: 'ローマ字以外の文字が含まれています',
  Containscharactersotherthanalphabetandnumbers: 'ローマ字と数字以外の文字が含まれています',
  Dateisnotbeforespecifieddate: '日付が指定された日付より前ではありません',
  Notaboolean: 'ブール値ではありません',
  notavalidcard: '有効なクレジットカードではありません',
  notavalidcurrencyamount: '有効な通貨額ではありません',
  Itsnotavaliddateornotinspecifiedformat: '有効な日付ではないか、指定された形式ではありませんか',
  Itsnotdivisibleby: '割り切れないです: ',
  PleaseenteravalidEmail: '正しいメールアドレスを入力してください!',
  IncorrectLengthlengthshouldbebetween: '長さが正しくありません...長さは',
  Notinlowercase: '小文字ではありません',
  Pleaseprovideacorrectphonenumber: '正しい電話番号を入力してください',
  NotaMongoDBID: 'MongoDB Object IDではありません',
  PleaseenteraNumber: '数字を入力してください',
  Notinuppercase: '大文字ではありません',
  specifiedliastisnotanarray: '指定された一覧は配列ではありません',
  valueisnotexcludedfromthelist: '値は一覧から除外されません',
  specifiedlistisnotanarray: '指定された一覧は配列ではありません',
  valueisnotincludedinthelist: '値は一覧に含まれていません',
  NotaUUID: 'UUIDではありません',
  NotaURL: 'URLではありません',
  ChangePassword: 'パスワードを変更する',
  Close: '閉める',
  Passwordshouldbeofminimum6digits: 'パスワードは6桁以上である必要があります',
  Passwordsdidnotmatch: 'パスワードが間違っています!',
  PasswordChangedSuccessfully: 'パスワードは正常に変更されました!',
  CurrentPassword: '現在のパスワード *',
  NewPassword: '新しいパスワード *',
  ConfirmNewPassword: '新しいパスワードを確認する *',
  Users: 'ユーザー',
  ConfigLayout: '構成レイアウト',
  English: 'English',
  Japanese: '日本語',
  Hindi: 'हिंदी',
  Italian: 'Italiano',
  French: 'français',
  German: 'Deutsche',
  Russian: 'русский',
  Korean: '한국어',
  Latin: 'Latine',
  Turkish: 'Türk',
  Spanish: 'Español',
  ModuleName: 'モジュール名',
  Cancel: 'キャンセル',
  EditNewField: '新しい項目を編集',
  Logout: 'ログアウト',
  AddNewValidation: '新しい検証を追加する',
  Validation: '検証',
  OpType: 'Opタイプ',
  CreatedAtDate: '作成日',
  CreatedAtTime: '作成時',
  UpdatedAtDate: '更新日',
  UpdatedAtTime: '更新時',
  ExportCSV: 'CSVダウンロード',
};
export default jp;
