function getUserAccessSchema(appAccessSchema, userRole) {
  const userAccessSchema = {
    component: {},
    buttons: {},
    table: {},
  };
  Object.keys(appAccessSchema || {}).forEach((accessSchema) => {
    if (appAccessSchema[accessSchema].accessTo?.stakeholders.includes(userRole)
      || appAccessSchema[accessSchema].accessTo?.stakeholders.includes('__d3__allSignedIN')
      || appAccessSchema[accessSchema].accessTo?.stakeholders.includes('__d3__public')
      || appAccessSchema[accessSchema].accessTo?.stakeholders.includes('__d3__any')) {
      (appAccessSchema[accessSchema].component || []).forEach((componentID) => {
        userAccessSchema.component[componentID] = true;
      });
      (appAccessSchema[accessSchema].buttons || []).forEach((buttonID) => {
        userAccessSchema.buttons[buttonID] = true;
      });
      // table related schema.
      (appAccessSchema[accessSchema].table || []).forEach((tableObj) => {
        const tableRights = {};
        (tableObj.rights || []).forEach((rights) => {
          tableRights[rights] = true;
        });
        const columnRoles = {};
        Object.keys(tableObj.columnRoles || {}).forEach((columnID) => {
          columnRoles[columnID] = {};
          (tableObj.columnRoles[columnID] || []).forEach((columnRole) => {
            columnRoles[columnID][columnRole] = true;
          });
        });
        userAccessSchema.table[tableObj.tableName] = {
          ...tableRights,
          ...columnRoles,
        };
      });
    } else {
      (appAccessSchema[accessSchema].component || []).forEach((componentID) => {
        userAccessSchema.component[componentID] = userAccessSchema.component[componentID] || false;
      });
      (appAccessSchema[accessSchema].buttons || []).forEach((buttonID) => {
        userAccessSchema.buttons[buttonID] = userAccessSchema.component[buttonID] || false;
      });
      (appAccessSchema[accessSchema].table || []).forEach((tableObj) => {
        const tableRights = {};
        (tableObj.rights || []).forEach((rights) => {
          tableRights[rights] = userAccessSchema.table[tableObj.tableName]?.[rights] || false;
        });
        const columnRoles = {};
        Object.keys(tableObj.columnRoles || {}).forEach((columnID) => {
          columnRoles[columnID] = {};
          (tableObj.columnRoles[columnID] || []).forEach((columnRole) => {
            columnRoles[columnID][columnRole] = userAccessSchema.table[tableObj.tableName]
              ?.[columnID]?.[columnRole] || false;
          });
        });
        userAccessSchema.table[tableObj.tableName] = {
          ...tableRights,
          ...columnRoles,
        };
      });
    }
  });
  return userAccessSchema;
}

export default getUserAccessSchema;
