import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { validate } from 'lib/utils-library';
import 'bootstrap/dist/css/bootstrap.min.css';

const CustomSelect = ({ d3Data, value = '', onChange = () => {}, onBlur = () => {}, err = '' }) => {
  // const [value, setValue] = useState(value || d3Data.defaultValue || '');
  const [error, setError] = useState(err || null);
  const [isEditing, setEditing] = useState(false);

  // useEffect(() => {
  //   // setValue(value || d3Data.defaultValue || '');
  // }, [d3Data.defaultValue]);

  const handleChange = (selectedOption) => {
    const newVal = selectedOption ? selectedOption.value : '';
    // setValue(newVal);
    onChange(newVal);
    const newError = validate(newVal, d3Data.validation);
    if (newError) {
      setError(newError.message);
    } else {
      setError(null);
    }
    setEditing(false);
  };

  const handleTextClick = () => {
    setEditing(true);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: err || error ? 'red' : provided.borderColor,
      '&:hover': {
        borderColor: err || error ? 'red' : provided.borderColor,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensures the dropdown is above other elements
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#009ef7',
    }),
  };

  return (
    <div className='row'>
      <label htmlFor={d3Data.columnName} className='col-sm-4 col-lg-3 col-form-label'>
        {d3Data.name}
      </label>
      <div className='col-sm-8 col-lg-9' style={{ height: '45px' }}>
        {isEditing ? (
          <Select
            id={d3Data.columnName}
            value={d3Data?.options?.selectMenu.find((option) => option.value === value)}
            onChange={handleChange}
            onBlur={onBlur}
            options={d3Data?.options?.selectMenu}
            styles={customStyles}
            autoFocus // Automatically focus when rendered
          />
        ) : (
          <span
            className='form-control-plaintext'
            style={{
              textDecoration: 'underline dotted',
              cursor: 'pointer',
              color: value ? 'inherit' : '#009ef7',
            }}
            onClick={handleTextClick}
          >
            {d3Data?.options?.selectMenu.find((option) => option.value === value)?.label ||
              'Click to edit'}
          </span>
        )}
        {(err || error) && <div className='invalid-feedback'>{err || error}</div>}
      </div>
    </div>
  );
};

export default CustomSelect;

CustomSelect.propTypes = {
  d3Data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired,
    type: PropTypes.string,
    validation: PropTypes.array,
    selectMenu: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  err: PropTypes.string,
};

CustomSelect.defaultProps = {
  value: '',
  onBlur: () => null,
  err: '',
};
