import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';
import React, { FC } from 'react';
import { IRoutes } from 'testData/DataModels';
import { useLayout } from '_metronic/layout/core';
// import { MenuInner } from './MenuInner';
type Props = {
  routes: IRoutes;
};

const Header: FC<Props> = ({ routes }) => {
  const { newScreenName } = routes;
  const { classes } = useLayout();
  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_toolbar_container'}"
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >
      <div
        className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'
        id='#kt_header_menu'
        data-kt-menu='true'
      >
        {mls(newScreenName)}
      </div>
    </div>
  );
};

export { Header };
