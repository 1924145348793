import getValidatedColumnSchema from 'utils/getValidatedColumnSchema';

const createTableView = (component, tableSchema) => {
  if (component?.tableList?.length > 0 && component.tableList[0].relationType === 'MAIN') {
    const columnSchema = getValidatedColumnSchema(
      tableSchema.columnSchema,
      component.tableList[0].defaultView,
      component.tableList
    );
    tableSchema = {
      ...tableSchema,
      columnSchema,
      isFormCustomization: !!component.codeCustomization?.[tableSchema.name]?.formValidation,
    };
  }
  return tableSchema;
};

export const applyRolesInTable = (component, tableSchema, teamIDs) => {
  if (teamIDs.includes('Admin') || teamIDs.includes('Creator') || teamIDs.includes('Public')) {
    tableSchema = createTableView(component, tableSchema);
    return tableSchema;
  }

  const roleAccess = component?.roleAccess || {};
  if (roleAccess) {
    const columnWideRole = {};
    let tableWideRole;
    (teamIDs || []).forEach((teamID) => {
      const userRole = roleAccess[tableSchema.name]?.[teamID];
      tableWideRole = [
        ...new Set([...(tableSchema['role'] || []), ...Object.keys(userRole || {})]),
      ];
      Object.keys(userRole || {}).forEach((method) => {
        (userRole[method]?.ColumnWiseRoles || []).map((columnName) => {
          columnWideRole[columnName] = {
            role: [...(columnWideRole[columnName]?.role || []), method],
          };
          return null;
        });
      });
    });

    const columnSchemaRoles = (tableSchema.columnSchema || []).map((columnObj) => {
      return {
        ...columnObj,
        ...columnWideRole[columnObj.columnName],
      };
    });
    tableSchema = {
      ...tableSchema,
      columnSchema: columnSchemaRoles,
      role: tableWideRole,
    };
  }

  return tableSchema;
};

function getComponentSchema(appSchema, componentID, teamIDs) {
  const component = appSchema.appComponent?.[componentID] || {};
  let tableSchema = appSchema?.appDatatable?.[component.table] || {};

  const actionButtons = component.codeCustomization?.[component.table]?.actionButton;

  // add roleaccess to tableSchema
  tableSchema = applyRolesInTable(component, tableSchema, teamIDs);
  tableSchema = createTableView(component, tableSchema);

  return { component, tableSchema, actionButtons };
}

export default getComponentSchema;
