/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useRef} from 'react'
import {KTSVG} from '_metronic/helpers'
import {Formik, Field, ErrorMessage, Form} from 'formik'
import {useState, useEffect} from 'react'
import {type} from 'os'
import {mls} from 'lib/multilanguagesupport'

const Step1 = ({value, setValue, schema}) => {
  const [step1, updateStep1] = useState(null)
  const [tags, setTags] = React.useState(value.campaignTags || [])
  const [imageURL, setImageURL] = useState(value.campaignImg)
  const fileRef = useRef(null)
  const ref = useRef(null)
  const updateValue = () => {
    if (ref.current.values !== null) {
      setValue({...value, ...ref.current.values, campaignTags: [...tags], campaignImg: imageURL})
      updateStep1(ref.current.values)
    }
    // console.log(step1)
  }

  const addTags = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      setTags([...tags, event.target.value])
      event.target.value = ''
    }
  }

  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)])
  }

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const initialValuesStep1 = value

  useEffect(() => {
    setValue({...value, ...step1, campaignTags: [...tags], campaignImg: imageURL})
    console.log(value)
  }, [step1, tags, imageURL])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>{mls('Create Campaign')}</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          {mls('Create your campaign & initial details')}
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            {mls('FAQ Page')}
          </a>
          .
        </div>
      </div>
      <Formik
        initialValues={initialValuesStep1}
        innerRef={ref}
        onSubmit={(values, actions) => {
          updateValue()
        }}
      >
        {() => (
          <Form onKeyDown={onKeyDown}>
            <div className='mb-10 fv-row'>
              <label className='form-label mb-3 text-gray-500 fw-bold fs-5'>
                {mls('Category')}:
              </label>
              <Field
                as='select'
                className='form-control form-control-lg form-control-solid mb-5'
                name='campaignCategory'
                onFocus={() => updateValue()}
                onBlur={() => updateValue()}
              >
                {schema.campaignCategory &&
                  schema.campaignCategory.map((Category) => (
                    <option value={Category}>{mls(Category)}</option>
                  ))}
              </Field>

              <label className='form-label mb-3 text-gray-500 fw-bold fs-5'>{mls('Header')}:</label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder={mls('Insert the header for your campaign')}
                name='campaignHeader'
                onFocus={() => updateValue()}
                onBlur={() => updateValue()}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='header' />
              </div>
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label text-gray-500 fw-bold fs-5'>{mls('Message')}:</label>

              <Field
                as='textarea'
                name='campaignBody'
                className='form-control form-control-lg form-control-solid'
                rows={4}
                onFocus={() => updateValue()}
                onBlur={() => updateValue()}
              ></Field>
            </div>
            {/* <div className='mb-10 fv-row'> */}
            {/* <label className='form-label mb-3 text-gray-500 fw-bold fs-5'>Add Image:</label> */}

            {/* {imageURL && (
                <div style={{height: '144px', width: '640px'}} className='mb-10 rounded'>
                  <span
                    style={{
                      height: '30px',
                      width: '30px',
                      left: '92%',
                      top: '25%',
                      background: ' #FFFFFF',
                      opacity: '0.25',
                    }}
                    className='px-3  position-relative py-2 rounded   text-center text-center'
                    onClick={() => setImageURL(null)}
                  >
                    <i className=' bi text-dark  bi-x-lg fw-bolder fs-6'></i>
                  </span>
                  <img
                    alt='not found'
                    height={'144px'}
                    width={'640px'}
                    className=' rounded w-100'
                    src={imageURL}
                  />
                </div>
              )}

              {!imageURL && (
                <>
                  <div
                    style={{height: '144px', backgroundColor: '#F5F8FA'}}
                    className=' rounded w-100'
                  >
                    <input
                      ref={fileRef}
                      onChange={(event) => {
                        setImageURL(URL.createObjectURL(event.target.files[0]))
                      }}
                      type='file'
                      hidden
                      className='my-17'
                    ></input>
                    <button
                      onFocus={() => someFuncton()}
                      onClick={(e) => {
                        fileRef.current.click()
                        e.preventDefault()
                      }}
                      className='btn-sm btn btn-primary my-15 '
                      style={{marginLeft: '250px'}}
                    >
                      Upload Image
                    </button>
                  </div>
                </>
              )} */}

            {/* <div className='text-danger mt-2'>
                <ErrorMessage name='header' />
              </div>
            </div> */}

            <div className=' w-100'>
              <label className='form-label text-gray-500 fw-bold fs-5'>{mls('Tags')}:</label>
              <div className=' mb-4'>
                {tags.map((tag, index) => (
                  <div className='d-inline-flex mb-3'>
                    <span className='px-3 py-2 bg-secondary me-4 rounded-pill' key={index}>
                      <span>{mls(tag)}</span>
                      <i
                        className='ps-2 align-middle bi bi-x fs-2 fw-bolder'
                        onClick={() => removeTags(index)}
                      ></i>
                    </span>
                  </div>
                ))}
              </div>
              <input
                className='form-control'
                type='text'
                onKeyUp={(event) => addTags(event)}
                placeholder={mls('Press enter to add tags')}
                style={{backgroundColor: '#F5F8FA'}}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export {Step1}
