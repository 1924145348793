import { FC } from 'react';
import { Error404 } from 'app/modules/errors/components/Error404';
import constants from 'commons/constants';
import useStoreSchema from 'commons/useStoreSchema';
import { Redirect, useLocation } from 'react-router-dom';
import reduxConstants from 'redux/reduxConstants';
import getConnectedScreenGroup from 'utils/getConnectedScreenGroup';
import getDefaultComponents from 'utils/getDefaultComponents';
import { MasterLayout } from '_metronic/layout/MasterLayout';

import useValidateResource from 'roles/validateResource';
import MessageHandling from 'screenComponents/MessageHandling';
import pluginsConstants from './pluginsConstants';

type Props = {
  match: {
    params: {
      appID: string, projectID: string, pluginType: string, pluginID: string, connectedScreenGroupID: string
    }
  }
};

const PluginRouteHandler: FC<Props> =({
  match: {
    params: { appID, projectID, pluginType, pluginID, connectedScreenGroupID },
  },
}) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const pluginSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.PLUGINS_SCHEMA);
  const appData = appSchema.app;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const currentScreenID = query.get('__d3__currentScreenID');
  const validateResource = useValidateResource();
  
  // resetting the URL according to the default URL.
  if (!pluginID) {
    return <Redirect to={`/${appID}/app`} />;
  }
  const isAccess = true; // componentIDs.map((componentID) => validateResource(constants.resourceType.COMPONENT, componentID)).some((access) => access !== false);
  const { schema, pluginNavDetails } = pluginSchema[pluginType];
  const Plugin = (pluginsConstants.pluginTypes as any)[pluginNavDetails.pluginType];
  // console.log(Plugin, 'pluginID');
  let pluginComponentDetails:{ screenID?: string, screenName?: string } = {};
  (pluginNavDetails.screen || []).forEach((screenObj:{ screenID: string, screenName: string }) => {
    if (screenObj.screenID === pluginID) {
      pluginComponentDetails = screenObj;
    }
  })
  let currentSchema = {};
  
  ((schema as any) || []).forEach((schemaObj: { _id: string}) => {    
    if (schemaObj._id === pluginID) {
      currentSchema = schemaObj;
    }
  });
  return (
    <MasterLayout
      appData={appData}
      routes={{ appID, newScreenName: pluginComponentDetails?.screenName }}
    >
      {
        isAccess && Plugin && <Plugin key={pluginID} appID={appID} pluginID={pluginID} schema={currentSchema} pluginNavDetails={pluginNavDetails} />
      }
      {
        !isAccess && <Error404 />
      }
      <MessageHandling />
    </MasterLayout>
  );
}
export default PluginRouteHandler;
