const constants = {
  resourceType: {
    COMPONENT: 'component',
    TABLE: 'table',
    BUTTONS: 'buttons',
    CREATE: 'c',
    READ: 'r',
    UPDATE: 'u',
    DELETE: 'd',
    COLUMN_CREATE: '__d3__columns_c',
    COLUMN_READ: '__d3__columns_r',
    COLUMN_UPDATE: '__d3__columns_u',
  }
};

export default constants;
