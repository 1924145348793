const jp = {
  'CSV Import': 'CSV読込',
  'CSV Export': 'CSV出力',
  'Table Options': 'テーブルオプション',
  'Import your existing CSV data to this table.': '既存のCSVデータをこのテーブルに読込します。',
  'Export the filtered CSV data of this table.': 'このテーブルのデータをCSVに出力します。',
  'Table Setting': 'テーブル設定',
  'Update the columns of this table.': 'このテーブルの列を更新します。',
  'You don\'t have sufficient rights to perform this action!': 'このアクションを実行するための十分な権限はありません。',
  'Rows deleted successfully': '行が正常に削除されました。',
  'There was a problem while deleting, please try again later.': '削除中に問題が発生しました。しばらくしてからもう一度お試しください。',
  'Row was already deleted.': '行はすでに削除されいてます。',
  'Rows created successfully': '行が正常に作成されました。',
  'There was a problem while creating row, please try again later!': '行の作成中に問題が発生しました。しばらくしてからもう一度お試しください。',
  'There was a problem while fetching App Schema, please try again later!': 'スキーマの取得中に問題が発生しました。しばらくしてからもう一度お試しください。',
  'Analytics': '分析',
  'Create an Account': 'アカウントを作成する。',
  'Sign In to Supista': 'Supistaにサインインする。',
  'New Here?': 'ここに初めてですか？',
  'Clear Filters': 'フィルターをクリア',
  'Page Size': 'ページサイズ',
  'No rows to show': '表示する行がありません。',
  'Sign Out': 'サインアウト',
  'Language': '言語',
  'Please wait while your rows are loading': '行が読み込まれるまでお待ちください。',
  'Deleting Rows': '行の削除',
  'Are you sure to delete the selected items?': '選択したアイテムを削除してもよろしいですか？',
  // version 2
  'all': '全て', 
  'today': '今日', 
  'this week' : '今週',
  'this month': '今月',
  'this year' : '今年',
  'custom date': '日付',
  'Filter Date': '日から選ぶ',
  'Select Custom Date': '日付を選択',
  'to': 'まで',
  // version 3
  'Row updated successfully': '行が正常に更新されました',
  'There was a problem while updating row , please try again later!': '行の更新中に問題が発生しました。後でもう一度やり直してください。',
  'ForgotPassword': 'パスワードをお忘れですか?',
  'CSV File to Upload:': 'アップロードするCSVファイル:',
  'Drag & drop some files here, or click to select files': 'ここにファイルをドラッグ＆ドロップするか、クリックしてファイルを選択しますか。',
  'Edit': '更新', 
};

export default jp;
