import { useSelector } from 'react-redux';
import get from 'lodash.get';

function useStoreSchema(storeName, schemaName) {
  const schema = useSelector(
    (state) => get(state[storeName], schemaName),
  );
  if (schema) {
    return schema;
  }
  return false;
}

export default useStoreSchema;
