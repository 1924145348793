import useStoreSchema from 'commons/useStoreSchema';
import { mls } from 'lib/multilanguagesupport';
import reduxConstants from 'redux/reduxConstants';

const textColumnsType = ['text', 'select', 'multipleSelect', 'email', 'checkbox', 'checkboxGroup'];
const numColumnsType = ['number', 'id', 'decimal', 'currency', 'belongsTo'];
const dateColumnsType = ['date', 'datePicker', '__d3__createdAt', '__d3__updatedAt'];

function checkCondition(condition, fieldValue, filterValue) {
  switch (condition) {
    case 'contains':
      return fieldValue.includes(filterValue);
    case 'notContains':
      return !fieldValue.includes(filterValue);
    case 'equals':
      return fieldValue === filterValue;
    case 'notEqual':
      return fieldValue !== filterValue;
    case 'startsWith':
      return fieldValue.startsWith(filterValue);
    case 'endsWith':
      return fieldValue.endsWith(filterValue);
    case 'in':
    case 'include':
      return filterValue.includes(fieldValue);
    case 'notIn':
    case 'notInclude':
      return !filterValue.includes(fieldValue);
    case 'regexp':
      return new RegExp(filterValue).test(fieldValue);
    case 'lessThan':
      return fieldValue < filterValue;
    case 'lessThanOrEqual':
      return fieldValue <= filterValue;
    case 'greaterThan':
      return fieldValue > filterValue;
    case 'greaterThanOrEqual':
      return fieldValue >= filterValue;
    case 'inRange':
      return fieldValue >= filterValue[0] && fieldValue <= filterValue[1];
    case 'TODAY':
      return isToday(new Date(fieldValue));
    case 'THISWEEK':
      return isThisWeek(new Date(fieldValue));
    case 'THISMONTH':
      return isThisMonth(new Date(fieldValue));
    case 'THISQUARTER':
      return isThisQuarter(new Date(fieldValue));
    case 'THISYEAR':
      return isThisYear(new Date(fieldValue));
    case 'LAST7DAYS':
      return isWithinLastDays(new Date(fieldValue), 7);
    case 'LAST30DAYS':
      return isWithinLastDays(new Date(fieldValue), 30);
    case 'LASTXDAYS':
      return isWithinLastDays(new Date(fieldValue), filterValue);
    case 'CUSTOM':
      return (
        new Date(fieldValue) >= new Date(filterValue.startDate) &&
        new Date(fieldValue) <= new Date(filterValue.endDate)
      );
    default:
      return false;
  }
}

function isToday(date) {
  const today = new Date();
  return date.toDateString() === today.toDateString();
}

function isThisWeek(date) {
  const today = new Date();
  const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
  const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  return date >= startOfWeek && date <= endOfWeek;
}

function isThisMonth(date) {
  const today = new Date();
  return date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}

function isThisQuarter(date) {
  const today = new Date();
  const currentQuarter = Math.floor((today.getMonth() + 3) / 3);
  const quarterStart = new Date(today.getFullYear(), (currentQuarter - 1) * 3, 1);
  const quarterEnd = new Date(today.getFullYear(), currentQuarter * 3, 0);
  return date >= quarterStart && date <= quarterEnd;
}

function isThisYear(date) {
  const today = new Date();
  return date.getFullYear() === today.getFullYear();
}

function isWithinLastDays(date, days) {
  const today = new Date();
  const pastDate = new Date(today.setDate(today.getDate() - days));
  return date >= pastDate;
}

// Main function to check if the row satisfies the filterSchema
function filterRows(filterSchema, rowData) {
  return rowData.filter((row) => {
    for (let key in filterSchema) {
      const filter = filterSchema[key];
      const column = filter.column;
      const dataType = filter.dataType;
      const condition = filter.value.condition;
      const filterValue = filter.value.value;
      const fieldValue = row[column];

      if (fieldValue === undefined) continue;

      if (dataType === 'text' || textColumnsType.includes(dataType)) {
        if (!checkCondition(condition, String(fieldValue), filterValue)) {
          return false;
        }
      } else if (numColumnsType.includes(dataType)) {
        if (!checkCondition(condition, Number(fieldValue), filterValue)) {
          return false;
        }
      } else if (dateColumnsType.includes(dataType)) {
        if (!checkCondition(filter.value.period, new Date(fieldValue), filterValue)) {
          return false;
        }
      }
    }
    return true;
  });
}

function ActionButtons({ actionButtons, operations, selectedNodesRef }) {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const userSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.USER_SCHEMA);
  const appID = appSchema?.app?.appID;

  const { allRoles } = userSchema;
  const teamID = allRoles?.[appID]?.erpRole || [];

  const applyFilters = (displayWhen, displayTo) => {
    // When both displaywhen (filter) and displayTo(teams) empty then return true
    if (Object.keys(displayWhen).length === 0 && Object.keys(displayTo).length === 0) {
      return true;
    }
    // When displayTo(teams) has teams but the logged in user's team is not one of them then return false
    if (Object.keys(displayTo).length > 0 && !displayTo.includes(teamID)) {
      return false;
    }

    // when either displayTo(teams) length is zero or it has the user's team and contains some displayWhen(filter) then it will run
    const selectedRows = operations.data?.rows.filter((row) =>
      selectedNodesRef.current.includes(row.id)
    );
    const filteredRows = filterRows(displayWhen, selectedRows);
    if (filteredRows.length === selectedRows.length) {
      return true;
    }
    return false;
  };

  const handleClick = (buttonId) => {
    operations.actionButton(
      buttonId,
      operations.data?.rows.filter((row) => selectedNodesRef.current.includes(row.id))
    );
  };
  return (
    <>
      {Object.keys(actionButtons).map((actionID) => {
        // validateResource(
        //   constants.resourceType.BUTTONS,
        //   button.actionButtonID
        // ) !== false &&
        const displayWhen = actionButtons[actionID]?.displayWhen || {};
        const displayTo = actionButtons[actionID]?.displayTo || {};
        const access = applyFilters(displayWhen, displayTo);
        if (!access) return <></>;
        return (
          <button
            key={actionID}
            className={`btn btn-sm btn-${actionID.color || 'primary'} me-3`}
            onClick={() => handleClick(actionID)}
          >
            {mls(actionButtons[actionID]?.title)}
          </button>
        );
      })}
    </>
  );
}

export default ActionButtons;
