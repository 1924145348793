import Campaigns from "plugins/campaigns";

const constants = {
  plugins: ['campaigns'],
  pluginTypes: {
    campaigns: Campaigns,
  },
  pluginData: {
    campaigns: {
      fetchURL: '/campaigns/getAllCampaignGroupSchema',
      // mapping plugin to the left side navigation.
      parseResponse: (pluginSchema, pluginObj) => {
        const schema = pluginSchema.response.campaignSchema.d3_resultVal;
        const pluginNavDetails = {
          name: pluginObj.name,
          id: pluginObj.pluginID,
          pluginType: 'campaigns',
          role: [''],
          icon: 'ri-notification-3-line',
          screen: schema.map((campaign) => ({ screenID: campaign._id, screenName: campaign.campaignGroupName })),
        }
        return { schema, pluginNavDetails };
      }
    }
  }
};
export default constants;
