import useStoreSchema from 'commons/useStoreSchema';
// import AgGridWrapper from 'lib/ag-grid-table';
import CreateUpdateWrapper from 'lib/ag-grid-table/CreateModal/CreateUpdateWrapper';
import { mls } from 'lib/multilanguagesupport';
import reduxConstants from 'redux/reduxConstants';
import getComponentSchema from 'screenComponents/getComponentSchema';

const HasOne = ({ relationSchema, dataTableSchema, componentID, parentRowID }) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const userSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.USER_SCHEMA);
  const appID = appSchema?.app?.appID;
  const { allRoles } = userSchema;
  const teamID = allRoles?.[appID]?.erpRole || [];
  const { component } = getComponentSchema(appSchema, componentID, teamID);
  return (
    <div className='shadow-sm rounded'>
      <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 pt-7 ps-10'>
        {relationSchema?.displayName || mls(component.name)}
      </div>
      <div>
        <CreateUpdateWrapper
          key={`${component.componentID}-${parentRowID}`}
          tableConfig={dataTableSchema}
          component={component}
          isUpdate={true}
          setModalOpen={() => {}}
          rowID={null}
          parentRowID={parentRowID}
          parentScreen={false}
          relationSchema={relationSchema}
        />
      </div>
    </div>
  );
};

export default HasOne;
