import { useState } from 'react';
import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { _supistaApiPost } from 'lib/server-connection/connections';
import { CircularProgress } from '@mui/material';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
const loginSchema = Yup.object().shape({
  OTP: Yup.string()
    .min(6, 'Please enter the 6-digit OTP.')
    .max(6, 'Please enter the 6-digit OTP.')
    .required('OTP is required'),
});

const initialValues = {
  OTP: '',
};

export const OtpForm = ({ appID, setIsOtpRequired, tempAppData, setTempAppData }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const data = {
        data: {
          OTP: `${values.OTP}`,
        },
      };

      _supistaApiPost(`auth/${appID}/validateOTP`, data)
        .then((res) => {
          setLoading(false);
          if (res.hasOwnProperty('__d3__success')) {
            dispatch(
              create({
                setPath: reduxConstants.config.LOGIN,
                value: { ...res.response, isLoggedin: res.response?.isLoggedin || false },
              })
            );
            dispatch(
              create({
                setPath: reduxConstants.config.USER_SCHEMA,
                value: res.response,
              })
            );
          } else {
            toast.error(mls('Could not verify OTP'));
          }
        })
        .catch(() => {
          setLoading(false);
          //  setStatus('Sorry, looks like there are some errors detected, please try again.');
        });
    },
  });
  // // The OTP field was being captured as touched by last step of entering email, and was showing green tick from mounting.
  // // Setting the field as untouched manually to display the green tick correctly
  // useEffect(() => {
  //   formik.setFieldTouched('OTP', false);
  // }, []);

  const handleBack = () => {
    setIsOtpRequired(false);
    setTempAppData({});
  };
  return (
    <div className='fv-row mb-10'>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <label className='form-label fw-bolder text-gray-900 fs-6'>{mls('OTP')}</label>
        <input
          type='number'
          placeholder='Enter OTP'
          onWheel={(e) => e.target.blur()}
          autoComplete='off'
          maxLength={6}
          {...formik.getFieldProps('OTP')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.OTP && formik.errors.OTP }
            // {
            //   'is-valid': formik.touched.OTP && !formik.errors.OTP,
            // }
          )}
        />
        {formik.touched.OTP && formik.errors.OTP && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{mls(formik.errors.OTP)}</span>
            </div>
          </div>
        )}
        <br />
        <div className='primaryButtonFlexDiv'>
          <button
            // onClick={() => checkOtp({ OTP: formik.getFieldProps('OTP')?.value ?? '' })}
            type='submit'
            className='primaryButton'
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            {mls('Submit')}
            {loading ? (
              <>
                &nbsp; &nbsp;
                <CircularProgress size={10} color='inherit' />
              </>
            ) : null}
          </button>
          <button
            type='button'
            onClick={() => handleBack()}
            className='primaryButton secondaryButtonColor'
          >
            {mls('Back')}
          </button>
        </div>
      </form>
    </div>
  );
};
