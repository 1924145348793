import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';

export const SetPasswordForm = ({ formik }) => {
  return (
    <>
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          {mls('Enter new password')}
        </label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('setPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.setPassword && formik.errors.setPassword },
            {
              'is-valid': formik.touched.setPassword && !formik.errors.setPassword,
            }
          )}
        />
        {formik.touched.setPassword && formik.errors.setPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='text-danger' role='alert'>
                {mls(formik.errors.setPassword)}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>{mls('Confirm password')}</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='text-danger' role='alert'>
                {mls(formik.errors.confirmPassword)}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
