import { mls } from 'lib/multilanguagesupport';
import React from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '_metronic/helpers';

function DeleteModal({
  deleteHeader,
  deleteBody,
  deleteData,
  setDeleting,
  selectedNodes,
  handleDeleteSuccess,
}) {
  return (
    <Modal show={true} onHide={() => setDeleting(false)} size='md'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h3>{deleteHeader || mls('Deleting Rows')}</h3>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setDeleting(false)}
            data-bs-dismiss='modal'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='d-flex flex-column-fluid'>
            <div className='pb-10'>
              {deleteBody || mls('Are you sure to delete the selected items?')}
            </div>
          </div>

          <div className='flex-row-fluid'>
            <div className='d-flex flex-stack pt-2' style={{ justifyContent: 'right' }}>
              <div>
                <button
                  className='btn btn-sm btn-light-primary me-3'
                  onClick={() => setDeleting(false)}
                >
                  {mls('Cancel')}
                </button>
                <button
                  onClick={async () => {
                    const deleted = await deleteData(selectedNodes);
                    console.log(deleted, 'deleteddeleted');
                    setDeleting(false);
                    handleDeleteSuccess();
                  }}
                  className='btn btn-sm btn-danger me-3'
                >
                  <span className='indicator-label'>
                    {mls('Delete')}
                    {/* <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    /> */}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteModal;
