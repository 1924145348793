const API = 'https://test.supista.com/api';
const schemasAPI = 'https://analytics-api.supista.com';

export const supistaApiGet = (endpoint) => {
  return fetch(`${API}/${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((response) => response.json());
};

export const supistaApiPost = async (endpoint, data) => {
  const cookiesStoredInLocalStorage = JSON.parse(localStorage.getItem('__d3__cookies'));
  if(cookiesStoredInLocalStorage) {
    data.__d3__cookies = cookiesStoredInLocalStorage;
  }
  else {
    data.__d3__cookies = {};
  }
  return fetch(`${API}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  }).then((response) => response.json())
    .then((response) => {
      if(response.hasOwnProperty('__d3__cookies')) {
        localStorage.setItem('__d3__cookies', JSON.stringify(response.__d3__cookies));
      }
      return response;
    });
};

export const supistaApiDelete = async (endpoint, data) => {
  const cookiesStoredInLocalStorage = JSON.parse(localStorage.getItem('__d3__cookies'));
  if(cookiesStoredInLocalStorage) {
    data.__d3__cookies = cookiesStoredInLocalStorage;
  }
  else {
    data.__d3__cookies = {};
  }
  return fetch(`${API}/${endpoint}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  }).then((response) => response.json())
    .then((response) => {
      if(response.hasOwnProperty('__d3__cookies')) {
        localStorage.setItem('__d3__cookies', JSON.stringify(response.__d3__cookies));
      }
      return response;
    });
};

export const supistaAnalyticsApi = async (endpoint, data) => {
  // console.log(JSON.stringify(data), "POST DATA");
  return fetch(`${schemasAPI}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  }).then((response) => response.json());
};