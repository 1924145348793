
import useStoreSchema from 'commons/useStoreSchema';
import reduxConstants from 'redux/reduxConstants';

function useValidateResource() {
  const userAccessSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.USER_ACCESS_SCHEMA);
  return (type, resourceID, operationID, columnName) => {
    if (type === 'table') {
      if (columnName) {
        return userAccessSchema.table?.[resourceID]?.[columnName]?.[operationID];
      }
      return userAccessSchema.table?.[resourceID]?.[operationID];
    }
    return userAccessSchema[type]?.[resourceID];
  }
}

export default useValidateResource;
