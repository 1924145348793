import DeleteModal from 'lib/ag-grid-table/CreateModal/DeleteModal';
import { mls } from 'lib/multilanguagesupport/index';
import React, { useEffect, useState } from 'react';
import CampaignsCardWrapper from './CampaignsCardWrapper';
import { Vertical } from './createCampaign/Vertical';
import { supistaAuthApiPost } from 'lib/server-connection/connections';

export default function Campaigns({ appID, pluginID, schema, pluginNavDetails, pluginType }) {
  const [isCreating, setCreating] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [updateCampaignData, setUpdateCampaignData] = useState();
  const [deleting, setDeleting] = useState(false);

  async function fetchNotifications() {
    const response = await supistaAuthApiPost(
      `plugins/${appID}/${pluginNavDetails.pluginType}/getCampaignSchema`,
      { campaignGroupID: pluginID },
      'GET'
    );
    console.log(response);
    if (response.__d3__success === true) {
      console.log(
        response.response.campaignSchema?.d3_resultVal,
        'response.response.campaignSchema?.d3_resultVal'
      );
      setNotificationList(response.response.campaignSchema?.d3_resultVal || []);
    } else setNotificationList([]);
  }
  useEffect(() => {
    if (!isCreating) {
      fetchNotifications();
    }
  }, [appID, isCreating, pluginID, pluginNavDetails?.pluginType]);
  // console.log(notificationList, !(notificationList instanceof Array));
  if (!(notificationList instanceof Array)) {
    return <></>;
  }

  const closeCampaignCreate = () => {
    setUpdateCampaignData();
    setCreating(false);
  };

  const addNewNotification = async (newNotification) => {
    const response = await supistaAuthApiPost(
      `plugins/${appID}/${pluginNavDetails.pluginType}/campaignSchema`,
      { data: { ...newNotification, campaignGroupID: pluginID } },
      'POST'
    );
    console.log(response, 'response');
    closeCampaignCreate();
  };

  const getOptions = async (columnName) => {
    const response = await supistaAuthApiPost(
      `app/${appID}/CRUD/${schema.tableName}/distinct`,
      { __d3__distinctColumnName: columnName },
      'GET'
    );
    console.log(response, 'response');
    return response;
  };
  const editCampaign = (id) => {
    notificationList.forEach((notification) => {
      if (notification._id === id) {
        setUpdateCampaignData(notification);
        setCreating(true);
        console.log(notification, 'notificationnotification');
      }
    });
  };
  const deleteCampaign = async (campaignID) => {
    const response = await supistaAuthApiPost(
      `plugins/${appID}/${pluginNavDetails.pluginType}/deleteCampaignSchema`,
      { data: { campaignID } },
      'DELETE'
    );
    if (response.__d3__success === true) {
      fetchNotifications();
    }
    console.log(response, 'response');
    return response;
  };
  const handleDeleteSuccess = () => {
    setDeleting(false);
  };

  const initiateDeleteSchema = (campaignID) => {
    setDeleting(campaignID);
  };
  console.log('abc');
  return (
    <div>
      {deleting ? (
        <DeleteModal
          deleteHeader={mls('Deleting Campaign')}
          deleteBody={mls('Are you sure to delete this campaign?')}
          deleteData={deleteCampaign}
          setDeleting={setDeleting}
          selectedNodes={deleting}
          handleDeleteSuccess={handleDeleteSuccess}
        />
      ) : (
        ''
      )}
      <div className='flex-grow-1'>
        <div className='d-flex justify-content-between align-items-start flex-wrap'>
          {isCreating ? (
            <div className='d-flex mb-4'>
              {
                <button
                  className='btn btn-sm btn-secondary me-3'
                  onClick={() => closeCampaignCreate()}
                >
                  {mls('Close')}
                </button>
              }
            </div>
          ) : (
            <>
              <div className='d-flex flex-column'>{/* Filters */}</div>
              <div className='d-flex mb-4'>
                {
                  <button className='btn btn-sm btn-primary me-3' onClick={() => setCreating(true)}>
                    {mls('Create')}
                  </button>
                }
              </div>
            </>
          )}
        </div>
      </div>
      {isCreating ? (
        <Vertical
          schema={schema}
          addNewNotification={addNewNotification}
          getOptions={getOptions}
          updateCampaign={updateCampaignData}
        />
      ) : (
        <CampaignsCardWrapper
          categories={schema.campaignCategory || []}
          notificationList={notificationList}
          editCampaign={editCampaign}
          deleteCampaign={initiateDeleteSchema}
        />
      )}
    </div>
  );
}
