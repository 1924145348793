import { useDispatch, useSelector } from "react-redux";
// import { useQuery } from 'react-query';
import reduxConstants from "redux/reduxConstants";
import { create } from "redux/stateSlice";
import { useState } from "react";
import { supistaAuthApiPost } from "lib/server-connection/connections";

function useAuthenticate() {
  const dispatch = useDispatch();
  useState(() => {
    supistaAuthApiPost(`validateUser/supista`, {}, 'GET').then((response) => {
      dispatch(create({
        setPath: reduxConstants.config.LOGIN,
        value: { ...response, isLoggedin: response.isLoggedin || false },
      }));
    }).catch(() => {});
  });

  const authData = useSelector(
    (state) => state[reduxConstants.STORE_NAME][reduxConstants.config.LOGIN],
  );

  if (authData?.isLoggedin === true) {
    return true;
  }
  return false;
}

export default useAuthenticate;
